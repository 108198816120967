import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import * as Msal from "msal";
import { LoginService } from '../../services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { accountType } from 'src/appLoginData';
import { configData } from 'src/configData';

// const msalConfig = configData.msalConfig;
// const clientId = msalConfig.auth.clientId;
// const authId = msalConfig.auth.authority;

const msalConfig = {
  auth: {
    clientId: "cae51fd0-123f-4bde-952d-622cf8b25757",
    authority: "https://login.microsoftonline.com/819948b9-e473-435d-b429-6f100444732f",
    //redirectUri: "http://localhost:4200/",
  }
};

let clientApplication = new Msal.UserAgentApplication(msalConfig);
let accessToken;
let AccountId;
let GroupNamess;
let GroupIds;

clientApplication.handleRedirectCallback(authRedirectCallBack);

function authRedirectCallBack(error, response) {
  let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";

  if (error) {
    console.log(error);
  }
  else {
    if (response.tokenType === "id_token") {
      console.log("id_token acquired at: " + new Date().toString());

      if (clientApplication.getAccount()) {
        console.log(clientApplication.getAccount())
        let accountid = response.accountIdentifier;
        let username = response.account.name;
        let token = response.idToken.rawIdToken;
        localStorage.setItem("AccountId", accountid);
        localStorage.setItem("UserName", username);
        localStorage.setItem("AccessToken", token);
      }

    } else if (response.tokenType === "access_token") {
      console.log("access_token acquired at: " + new Date().toString());
      accessToken = response.accessToken;

      try {
        //this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
        //this.callMSGraph("https://graph.microsoft.com/beta/me", accessToken);
      } catch (err) {
        console.log(err)
      } finally {
        //   profileButton.classList.add('d-none');
        //   mailButton.classList.remove('d-none');
      }
    } else {
      console.log("token type is:" + response.tokenType);
    }
  }
}

const loginRequest = {
  scopes: ["User.Read", "Group.Read.All"]

};


// function getTokenRedirect(loginRequest, endpoint) {
//   return clientApplication.acquireTokenSilent(loginRequest)
//       .then((response) => {
//         console.log(response);
//         if (response.accessToken) {
//             console.log("access_token acquired at: " + new Date().toString());
//             accessToken = response.accessToken;

//             if (accessToken) {
//               try {
//                 callMSGraphForGroups(endpoint, response.accessToken, getGroupNames) 
//               } catch(err) {
//                 console.log(err)
//               } 
//             }
//         }
//       })
//       .catch(error => {
//           console.log("silent token acquisition fails. acquiring token using redirect");
//           // fallback to interaction when silent call fails
//           return clientApplication.acquireTokenRedirect(loginRequest);
//       });
// }

// let endpoint= "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
//     clientApplication.acquireTokenSilent(loginRequest).then(response => {
//       //callMSGraphForGroups("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", response.accessToken, getGroupNames);
//         console.log(clientApplication.getAccount(), "AccountTestNew")
//        // localStorage.setItem('SeesionUser',this.user3) 
//         AccountId = response.account.accountIdentifier;
//         callMSGraphForGroups(endpoint, response.accessToken, getGroupNames)  
//         //return false;

//         }).catch(error => {
//             //console.log(error);
//         });

//         function callMSGraph(endpoint, token, getGroupNames) {
//           const headers = new Headers();
//           const bearer = `Bearer ${token}`;

//           headers.append("Authorization", bearer);

//           const options = {
//               method: "GET",
//               headers: headers
//           };

//           console.log('request made to Graph API at: ' + new Date().toString());

//           fetch(endpoint, options)
//             .then(response => response.json())
//             .then(response => getGroupNames(response, endpoint))
//             .catch(error => console.log(error))
//         }

//         function callMSGraphForGroups(endpoint, token, getGroupNames) {
//             const headers = new Headers();
//             const bearer = `Bearer ${token}`;

//             headers.append("Authorization", bearer);

//             const options = {
//                 method: "GET",
//                 headers: headers
//             };

//             console.log('request made to Graph API for group names at: ' + new Date().toString());

//             fetch(endpoint, options)
//                 .then(response => response.json())
//                 .then(response => getGroupNames(response, endpoint))
//                 .then(response => callMSGraph("https://graph.microsoft.com/beta/me", token, getGroupNames))
//                 .catch(error => console.log(error))
//           }

//           function getGroupNames(data, endpoint){

//             console.log('Graph API responded at: ' + new Date().toString());

//               if (endpoint === "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/") {
//                   if (data.value.length > 0) {
//                       var arr = data.value;
//                       var displayNameArr = [];
//                       var groupIds = [];
//                       for (var i = 0; i < arr.length; i++) {
//                           displayNameArr.push(arr[i].displayName);
//                           groupIds.push(arr[i].id);
//                       }
//                       GroupNamess  = displayNameArr.join();
//                       GroupIds = groupIds.join();
//                       localStorage.setItem("ADGroupNames", GroupNamess );
//                       localStorage.setItem("GroupIds", GroupIds);
//                   }
//               }
//             }



function signIn() {
  clientApplication.loginRedirect(loginRequest);
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  user = '1';
  user2;
  user3;
  msalConfig;
  loginRequest;
  AccountId;
  GroupNamess;
  GroupIds;
  tokenRequest;
  getscreenaccesspermissions;
  screennames;
  username;
  loginDetails;
  radioselected: string;
  loginForm: FormGroup;
  submitted = false;
  buttonDisabled: boolean;
  formBasedAuthLogin: any;
  isError: boolean = false;
  public baseapiurl;
  public getbaseapiurl;
  public clientApplication: Msal.UserAgentApplication = null;
  constructor(private route: ActivatedRoute, private SpinnerService: NgxSpinnerService, public loginservice: LoginService, private formbld: FormBuilder, private router: Router, private toastr: ToastrService,) {

    this.msalConfig = {
      auth: {
        clientId: "cae51fd0-123f-4bde-952d-622cf8b25757",
        authority: "https://login.microsoftonline.com/819948b9-e473-435d-b429-6f100444732f",
        //redirectUri: "http://localhost:4200/",
      },
      // cache: {
      //   cacheLocation: "sessionStorage", // This configures where your cache will be stored
      //   storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      // }
    };

    // this.clientApplication = new Msal.UserAgentApplication(this.msalConfig);
    this.formBasedAuthLogin = accountType.formBasedAuthLogin;
    this.loginForm = this.formbld.group({
      Admin1: new FormControl([
        //  Validators.required,

      ]),
      Admin2: new FormControl([
        //  Validators.required,

      ]),
      Initiator: new FormControl([
        //  Validators.required,

      ]),
    });
  }

  ngOnInit(): void {
    this.msalConfig = {
      auth: {
        clientId: "cae51fd0-123f-4bde-952d-622cf8b25757",
        authority: "https://login.microsoftonline.com/819948b9-e473-435d-b429-6f100444732f",
        //redirectUri: "http://localhost:4200/",
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      }
    };

    this.loginRequest = {
      scopes: ["User.Read", "Group.Read.All"]

    };
    var InLogin = localStorage.getItem('inLogin');
    if (InLogin == "true") {
      this.router.navigate(['/initiator']);
    }
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
    this.getAccountType();
    // Add here scopes for access token to be used at MS Graph API endpoints.
    this.tokenRequest = {
      scopes: ["Mail.Read", "User.Read", "Group.Read.All"]
    };

    console.log(clientApplication.getAccount(), "Account");

    if (clientApplication.getAccount()) {


      this.SpinnerService.show();

      clientApplication.handleRedirectCallback(authRedirectCallBack);
      this.accquiretoken();
      // let token = localStorage.getItem("AccessToken");
      // let endpoint= "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
      // clientApplication.acquireTokenSilent(this.loginRequest).then(response => {
      //   //callMSGraphForGroups("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", response.accessToken, getGroupNames);
      //     console.log(clientApplication.getAccount())
      //     localStorage.setItem('SeesionUser',this.user3) 
      //     this.AccountId = response.account.accountIdentifier;
      //     this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)  
      //     //return false;

      //     }).catch(error => {
      //         //console.log(error);
      //     }); 
    }

  }

  accquiretoken() {
    let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
    return clientApplication.acquireTokenSilent(this.loginRequest)
      .then((response) => {
        console.log(response);
        if (response.accessToken) {
          console.log("access_token acquired at: " + new Date().toString());
          accessToken = response.accessToken;
          this.AccountId = response.account.accountIdentifier;
          if (accessToken) {
            try {
              this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
            } catch (err) {
              console.log(err)
            }
          }
        }
      })
      .catch(error => {
        console.log("silent token acquisition fails. acquiring token using redirect");
        // fallback to interaction when silent call fails
        return clientApplication.acquireTokenRedirect(this.loginRequest);
      });
  }

  getAccountType() {
    // var data = true; 
    // return data;
    this.loginForm.controls['email'].reset();
    this.loginForm.controls['password'].reset();
    localStorage.setItem('formbasedauthh', this.formBasedAuthLogin);
    // this.loginservice.getFormBasedLogin().then((res) => {
    //   console.log(res);
    //   if (res.StatusCode == 200) {
    //     if (res.Data == "true") {
    //       this.formBasedAuthLogin = true;
    //     } else {
    //       this.formBasedAuthLogin = false;
    //     }
    //     localStorage.setItem('formbasedauthh', this.formBasedAuthLogin);
    //   }
    //   else {
    //     alert('error in database');
    //   }
    // });
  }

  onLoginNew() {
    signIn();
    //const msalInstance = new Msal.UserAgentApplication(this.msalConfig);
    //this.clientApplication.loginRedirect(this.loginRequest)

  }
  onloginForm() {
    if (this.loginForm.valid) {
      this.loginDetails = {
        'UserEmailId': this.loginForm.controls['email'].value,
        'Password': this.loginForm.controls['password'].value
      }
      console.log(this.loginDetails);
      this.SpinnerService.show();
      this.loginservice.getFormBasedLogin(this.loginDetails).then(res => {
        console.log(res);
        if (res.StatusCode == 200) {
          this.SpinnerService.hide();
          var roles;
          roles = res.Data.Roles;
          let username = res.Data.UserName;
          let newrolarr = [];
          newrolarr = roles;
          localStorage.setItem("roles", roles);
          var screenname = []
          screenname = res.Data.ScreenNames;
          localStorage.setItem("ScreenAcessPermission", JSON.stringify(screenname));
          localStorage.setItem("UserName", username);
          localStorage.setItem("roles", roles);
          localStorage.setItem("IniRoles", roles);
          let newroles1 = JSON.stringify(newrolarr);
          localStorage.setItem("NewRoles", newroles1);
          this.router.navigate(['/engagementletterdashboard']);
          localStorage.setItem("ADGroupNames", roles);
          localStorage.setItem('ScreenNames', JSON.stringify(screenname));
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();
          this.isError = true;
          this.loginForm.controls['email'].reset();
          this.loginForm.controls['password'].reset();
          var authError = "<p>User mail and password is incorrect</p>"
        }
      });
    }
  }

  onLogin() {
    this.SpinnerService.show();
    const myMSALObj = new Msal.UserAgentApplication(this.msalConfig);

    myMSALObj.loginPopup(this.loginRequest).then(loginResponse => {
      console.log("id_token acquired at: " + new Date().toString());
      console.log(loginResponse);
      this.AccountId = loginResponse.uniqueId;
      this.username = loginResponse.account.name;
      localStorage.setItem("UserName", this.username);
      let endpoint = "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/";
      if (myMSALObj.getAccount()) {
        myMSALObj.acquireTokenSilent(this.loginRequest).then(response => {
          //callMSGraphForGroups("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true", response.accessToken, getGroupNames);
          console.log(myMSALObj.getAccount())
          localStorage.setItem('SeesionUser', this.user3)

          this.callMSGraphForGroups(endpoint, response.accessToken, this.getGroupNames)
          //return false;

        }).catch(error => {
          //console.log(error);
        });
      }
      else {
        this.toastr.error("Login Failed");
        return false;
      }

    }).catch(error => {
      console.log(error);
    });
  }



  callMSGraph(endpoint, token, getGroupNames) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };

    console.log('request made to Graph API at: ' + new Date().toString());

    fetch(endpoint, options)
      .then(response => response.json())
      .then(response => this.getGroupNames(response, endpoint))
      .catch(error => console.log(error))
  }

  callMSGraphForGroups(endpoint, token, getGroupNames) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };

    console.log('request made to Graph API for group names at: ' + new Date().toString());

    fetch(endpoint, options)
      .then(response => response.json())
      .then(response => this.getGroupNames(response, endpoint))
      .then(response => this.callMSGraph("https://graph.microsoft.com/beta/me", token, getGroupNames))
      .catch(error => console.log(error))
  }


  getGroupNames(data, endpoint) {

    console.log('Graph API responded at: ' + new Date().toString());

    if (endpoint === "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/") {
      if (data.value.length > 0) {
        var arr = data.value;
        var displayNameArr = [];
        var groupIds = [];
        for (var i = 0; i < arr.length; i++) {
          displayNameArr.push(arr[i].displayName);
          groupIds.push(arr[i].id);
        }
        this.GroupNamess = displayNameArr.join();
        this.GroupIds = groupIds.join();
        localStorage.setItem("ADGroupNames", this.GroupNamess);
      }
    }
    this.getscreenaccesspermissions = {
      "ClientId": this.AccountId,
      "GroupIds": this.GroupIds
    }

    console.log(this.getscreenaccesspermissions, "screenpermAPIJSON");

    this.loginservice.getScreenPermissions(this.getscreenaccesspermissions).then((res) => {
      if (res.StatusCode == 200) {
        console.log(res);
        localStorage.setItem('ScreenNames', JSON.stringify(res.Data.ScreenNames));
        localStorage.setItem('SPL', JSON.stringify(res.Data.ScreenPermissionList));
        localStorage.setItem("UserId", res.Data.UserId);
        let screenname;

        if (res.Data.ScreenNames == null || res.Data.ScreenNames == '') {
          screenname = "Letter Generation";
        }
        else {
          screenname = res.Data.ScreenNames;
        }
        var roles;
        if (res.Data.Roles == null || res.Data.Roles == '') {
          roles = "Initiator";
          let newrolarr = [];
          newrolarr.push(roles);
          localStorage.setItem("roles", roles);
          localStorage.setItem("ScreenAcessPermission", screenname);
          this.SpinnerService.hide();
          //this.router.navigate(['/initiator']);
          this.router.navigate(['/engagementletterdashboard']);
          localStorage.setItem("IniRoles", roles);
          let newroles1 = JSON.stringify(newrolarr);
          localStorage.setItem("NewRoles", newroles1);


        }
        else {
          let newRoles = res.Data.Roles;
          roles = res.Data.Roles[0];

          let newroles1 = JSON.stringify(newRoles);
          localStorage.setItem("NewRoles", newroles1);
          localStorage.setItem("IniRoles", newRoles);
          console.log((newroles1));
          console.log(JSON.parse(newroles1));
          console.log(roles);
          console.log(screenname);
          localStorage.setItem("roles", roles);
          localStorage.setItem("ScreenAcessPermission", screenname);
          this.SpinnerService.hide();
          //this.router.navigate(['/initiator']);
          this.router.navigate(['/engagementletterdashboard']);
        }
      }
    });

  }
  getGroupNamesFormLogin(UserData: any) {
    this.getscreenaccesspermissions = {
      "ClientId": 1,
      "GroupIds": UserData.GroupId
    }
    this.baseapiurl = environment.baseApiUrl;
    this.getbaseapiurl = this.baseapiurl.split('/Api')[0];
    this.loginservice.getScreenPermissions(this.getscreenaccesspermissions).then((res) => {
      if (res.StatusCode == 200) {
        console.log(res);
        localStorage.setItem('ScreenNames', JSON.stringify(res.Data.ScreenNames));
        localStorage.setItem('SPL', JSON.stringify(res.Data.ScreenPermissionList));
        localStorage.setItem("UserId", res.Data.UserId);
        let screenname;

        if (res.Data.ScreenNames == null || res.Data.ScreenNames == '') {
          screenname = "Letter Generation";
        }
        else {
          screenname = res.Data.ScreenNames;
        }
        var roles;
        if (res.Data.Roles == null || res.Data.Roles == '') {
          roles = "Initiator";
          let newrolarr = [];
          newrolarr.push(roles);
          localStorage.setItem("roles", roles);
          localStorage.setItem("ScreenAcessPermission", screenname);
          this.SpinnerService.hide();
          this.router.navigate(['/engagementletterdashboard']);
          localStorage.setItem("IniRoles", roles);
          let newroles1 = JSON.stringify(newrolarr);
          localStorage.setItem("NewRoles", newroles1);


        }
        else {
          let newRoles = res.Data.Roles;
          roles = res.Data.Roles[0];

          let newroles1 = JSON.stringify(newRoles);
          localStorage.setItem("NewRoles", newroles1);
          localStorage.setItem("IniRoles", newRoles);
          console.log((newroles1));
          console.log(JSON.parse(newroles1));
          console.log(roles);
          console.log(screenname);
          localStorage.setItem("roles", roles);
          localStorage.setItem("ScreenAcessPermission", screenname);
          this.SpinnerService.hide();
          this.router.navigate(['/engagementletterdashboard']);
        }
      }
    });


  }
}
