import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { FieldService } from 'src/app/services/field.service';
import { QuestionsService } from 'src/app/services/questions.service';
import { ToastService } from 'src/app/shared/toast.service';
import { CacheclearService } from 'src/app/services/cacheclear.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  //  Question List
  isChecked: boolean;
  isButtonEnabled = true;
  questionValues = [];

  // Modal variables
  displayLogout = "none";
  displayWarningModal = "none";
  warningMessage: string;
  errorMessage: string;
  displaySuccessModal = "none";
  displayErrorModal = "none";
  displayDeleteModal = "none";
  successMessage: string;
  secondPopupOpen: boolean = false;
  public questionIdCount: any;
  public count;

  // Question Variables

  public validation_messages;


  // delete

  deleteId: any;
  anyCheckboxSelected: boolean = false;
  public totalDeleteId;
  public emptyarray = [];
  public selectedProducts: any;
  public selectedDeleteProducts: any;
  //common variables
  ScreenName: string = 'Questions';
  typeSelected: string;
  Username: any;
  trustedHtml: SafeHtml;
  displayAddQuestion = "none";
  displayEditQuestion = "none";

  //add Question  & edit Question

  createQuestionForm: FormGroup;
  editQuestionForm: FormGroup;
  StatusValue: any;
  isReadstatusOnly: boolean = false;
  model: any = {};
  selectedStatus: any;
  questionidmodel = {};
  viewFieldJsonArray: any;
  editjsonvalue: any = {};

  // add & edit Response options

  addResponseOptions: any = [];
  minresponseoptionvalue: any;
  public optionNameexist: any;
  //Edit
  questionidView: any;
  questionnameView: any;
  questionresponsetypeView: any;
  questionstatusview: any;
  questionresponseoptionView: any;
  ConnectedTemplates: any;
  viewStatus: any;
  statusdata: any;
  optionsarray: any = [];
  questionchangenotesView: any;
  public viewjsonarray;
  statusId;
  optionval: any = [];
  statusName: any;

  @ViewChild('maintoggledrop', { static: false }) maintoggledrop: ElementRef;
  @ViewChild('qt') table: Table;
  @ViewChild("del", { static: false }) del: ElementRef;

  constructor(public SpinnerService: NgxSpinnerService, public questionservice: QuestionsService, public fieldservice: FieldService, private renderer: Renderer2, public sanitizer: DomSanitizer, private formbuild: FormBuilder, private toast: ToastService, private toastr: ToastrService,private CacheclearService: CacheclearService) {
    this.typeSelected = 'timer';
    this.createQuestionForm = this.formbuild.group({
      QuestionID: new FormControl([]),
      QuestionText: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(255), Validators.required])],
      ResponseOption: ['', [Validators.minLength(2), Validators.maxLength(50)]],
      ResponseType: ['RadioGroup', [Validators.required]],
      Status: ['', [Validators.required]],
      ChangeNotes: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), , this.minLengthWithoutLeadingSpaces(5)]],
    });

    this.editQuestionForm = this.formbuild.group({
      QuestionID: new FormControl([]),
      QuestionText: ['', Validators.compose([Validators.minLength(3), Validators.maxLength(255), Validators.required])],
      ResponseOption: ['', [Validators.minLength(2), Validators.maxLength(50)]],
      ResponseType: ['RadioGroup', [Validators.required]],
      Status: ['', [Validators.required]],
      ChangeNotes: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(255), , this.minLengthWithoutLeadingSpaces(5)]],
    });

    this.validation_messages = {
      'QuestionID': [

      ],
      'QuestionText': [
        { type: 'required', message: this.toast.NameError },
        { type: 'maxlength', message: this.toast.questionMinCharactersOnly },
        { type: 'minlength', message: this.toast.questionMaxCharactersOnly },
      ],
      'ResponseOption': [
        { type: 'required', message: this.toast.NameError },
        { type: 'maxlength', message: this.toast.responseMaxCharactersOnly },
        { type: 'minlength', message: this.toast.responseMinCharactersOnly },
      ],
      'ResponseType': [
        { type: 'required', message: this.toast.TypeError },

      ],
      'Status': [
        { type: 'required', message: this.toast.toastError },

      ],
      'ChangeNotes': [
        { type: 'required', message: this.toast.ChangeNotesError },
        { type: 'maxlength', message: this.toast.changenotesquesCharactersOnly },
        { type: 'minlength', message: this.toast.changenotesminquesCharactersOnly },
      ],
    }

  }

  ngOnInit(): void {
    // console.log = function () { };
    this.Username = localStorage.getItem("UserName");
    this.SpinnerService.show();
    this.getQuestionList();
    this.onClearQuestion();
  }
  getQuestionList() {
    this.questionservice.listquestiondata().then((res) => {
      this.SpinnerService.hide();
      if (res.StatusCode == 200) {
        this.questionValues = res.Data;
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 204) {
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Question Cannot be Added.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else if (res.StatusCode == 406) {
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Question Text Already Exists.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 500) {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        //this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  isAllCheckBoxChecked() {
    return this.questionValues.length > 0 && this.questionValues.every(item => item.checked);
  }

  checkAllCheckBox(event) {
    this.isChecked = event.target.checked;
    this.isButtonEnabled = !this.isChecked;
    this.questionValues.forEach(item => item.checked = this.isChecked);
  }

  toggleSingleCheckbox(id: number, isChecked: boolean) {
    this.deleteId = id;
    this.anyCheckboxSelected = this.questionValues.some(field => field.checked);
    this.isButtonEnabled = !this.anyCheckboxSelected;
  }


  addQuestionModal() {
    this.GetAllStatus();
    // this.count = 1;
    setTimeout(() => {
      this.createQuestionForm.patchValue({
        ResponseType: "RadioGroup",
      });
    }, 100);

    this.displayAddQuestion = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
    this.SpinnerService.show();
    this.questionservice.listquestioniddata('')?.then((res) => {
      this.SpinnerService.hide();
      if (res.Data != '') {
        console.log(res);
        this.questionIdCount = res.Data[0].Id;
        this.count = this.questionIdCount + 1;
      }
      else {
        this.count = 1;
      }
    });
  }

  public addDiv() {
    this.trustedHtml = this.sanitizer.bypassSecurityTrustHtml("<div id='demo' class='modal-backdrop show'></div>")
  }

  addClassInDashboard(content: string) {
    if (content == 'sidebar-open') {
      this.renderer.addClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    } else {
      this.renderer.removeClass(this.maintoggledrop.nativeElement, 'sidebar-open');
    }
  }

  saveQuestion() {

    let questionnamecheck = this.createQuestionForm.controls['QuestionText'].value.trim();
    const responseTypecreate = this.createQuestionForm.get('ResponseType').value;
    console.log(this.addResponseOptions.length);
    if (responseTypecreate == "RadioGroup" && this.addResponseOptions.length == 1) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Option Minimum Two for Radio Group.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
    if (responseTypecreate == "Dropdown" && this.addResponseOptions.length == 1) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Option Minimum Two for Dropdown.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
    let status = this.createQuestionForm.get('Status').value;
    const statusId = status.StatusId;

    const QuestionsModels = {
      QuestionText: this.createQuestionForm.get('QuestionText').value.trim(),
      ResponseType: this.createQuestionForm.get('ResponseType').value,
      ResponseOption: this.addResponseOptions.join(),
      ChangeNotes: this.createQuestionForm.get('ChangeNotes').value.trim(),
      IsDeleted: false,
      StatusId: statusId,
      CreatedBy: this.Username,
    };
    this.SpinnerService.show();
    this.questionservice.postSaveQuestionData(QuestionsModels)?.then((data) => {
      if (data.StatusCode == 200) {
        this.createQuestionForm.controls['QuestionText'].reset();
        this.createQuestionForm.controls['ResponseType'].reset();
        this.createQuestionForm.controls['ResponseOption'].reset();
        this.createQuestionForm.controls['Status'].reset();
        this.createQuestionForm.controls['ChangeNotes'].reset();
        this.addResponseOptions.splice(0);
        this.successMessage = "Question Created Successfully";
        this.displaySuccessModal = 'block';
        this.displayAddQuestion = 'none';
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
        this.getQuestionList();
      }
      else if (data.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (data.StatusCode == 401 || data.StatusCode == 400 || data.status == 400 || data.status == 401) {
        this.SpinnerService.hide();
      }
      else if (data.StatusCode == 500) {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }
  UpdateQuestion() {

    let questionnamecheck = this.editQuestionForm.controls['QuestionText'].value.trim();
    const responseTypecreate = this.editQuestionForm.get('ResponseType').value;
    console.log(this.optionsarray.length);
    if (responseTypecreate == "RadioGroup" && this.optionsarray.length == 1) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Option Minimum Two for Radio Group.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
    if (responseTypecreate == "Dropdown" && this.optionsarray.length == 1) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Option Minimum Two for Dropdown.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
    let status = this.editQuestionForm.get('Status').value;
    if (status.StatusId) {
      this.statusName = status.StatusName;
    } else {
      this.statusName = status;
    }

    const QuestionsModels = {
      QuestionsId: this.editQuestionForm.get('QuestionID').value,
      QuestionText: this.editQuestionForm.get('QuestionText').value.trim(),
      ResponseType: this.editQuestionForm.get('ResponseType').value,
      ResponseOption: this.optionsarray.join(),
      ChangeNotes: this.editQuestionForm.get('ChangeNotes').value.trim(),
      IsDeleted: false,
      StatusId: this.statusName == "Active" ? 1 : 2,
      ModifiedBy: this.Username,
    };
    console.log(QuestionsModels);

    this.SpinnerService.show();
    this.questionservice.putUpdateQuestionData(QuestionsModels)?.then((data) => {
      if (data.StatusCode == 200) {
        console.log(data);
        this.successMessage = "Question Updated Successfully";
        this.displaySuccessModal = 'block';
        this.displayEditQuestion = "none";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
        this.editQuestionForm.controls['QuestionText'].reset();
        this.editQuestionForm.controls['ResponseOption'].reset();
        this.editQuestionForm.controls['Status'].reset();
        this.editQuestionForm.controls['ChangeNotes'].reset();
        this.optionsarray.splice(0);
        this.getQuestionList();
      }
      else if (data.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (data.StatusCode == 401 || data.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else if (data.StatusCode == 500) {
        this.SpinnerService.hide();
        this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = data.ErrorMessage ? data.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  CloseQuestionModal() {
    this.displayAddQuestion = 'none';
    this.displayEditQuestion = 'none';
    this.onClearQuestion();
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  closeErrorModal() {
    this.displayErrorModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      let myobj = document.getElementById("demo");
      myobj?.remove();
    }
  }

  deleteQuestionModal() {
    this.displayDeleteModal = "block";
    this.renderer.addClass(document.body, 'modal-open');
    this.addDiv();
  }

  onCloseDeleteModal() {
    this.displayDeleteModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
    this.isChecked = false;
    this.questionValues.forEach(item => item.checked = false);
    this.isButtonEnabled = true;
  }

  closeWarningModal() {
    this.displayWarningModal = "none";
    if (this.secondPopupOpen == true) {
      this.secondPopupOpen = false;
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
      let myobj = document.getElementById("demo");
      myobj?.remove();
    }
  }

  onDeleteQuestion() {
    this.selectedDeleteProducts = this.questionValues.filter(question => question.checked).map(question => question.QuestionsId);
    console.log(this.selectedDeleteProducts);
    if (this.selectedDeleteProducts.length == 1) {
      console.log(this.selectedDeleteProducts.length);
      this.totalDeleteId = this.selectedDeleteProducts;
      var deleteData = {
        "QuestionId": this.totalDeleteId,
        "DeletedBy": this.Username,
        "IsDeleted": true,
      }
      this.SpinnerService.show();
      this.questionservice.deleteQuestionData(deleteData)?.then((res) => {
        this.SpinnerService.hide();
        this.displayDeleteModal = "none";
        if (res.StatusCode == 200) {
          if (res.Status == true) {
            this.successMessage = "Question Deleted Successfully"
            this.displaySuccessModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getQuestionList();
          }
        }
        else if (res.status == 422) {
          this.SpinnerService.hide();
          this.clearCacheCommonLogout();
        }
        else if (res.StatusCode == 406) {
          if (res.Status == false) {

            this.warningMessage = res.ErrorMessage;
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getQuestionList();

          }
        }
        else if (res.StatusCode == 300) {
          if (res.Status == true) {
            this.warningMessage = res.ErrorMessage;
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getQuestionList();
          }
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400) {
          this.SpinnerService.hide();
        }
        else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
      this.isButtonEnabled = true;
    }

    else {
      var totalDeleteIdArray: any = [];

      for (let i = 0; i < this.selectedDeleteProducts.length; i++) {
        this.totalDeleteId = this.selectedDeleteProducts[i];
        totalDeleteIdArray.push(this.totalDeleteId);
      }
      var deletemoreData = {
        "QuestionId": totalDeleteIdArray,
        "DeletedBy": this.Username,
        "IsDeleted": true,

      }
      this.SpinnerService.show();
      this.questionservice.deleteQuestionData(deletemoreData)?.then((res) => {
        this.SpinnerService.hide();
        this.displayDeleteModal = "none";
        if (res.StatusCode == 200) {
          if (res.Status == true) {
            this.successMessage = "Question Deleted Successfully"
            this.displaySuccessModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getQuestionList();
          }
        }
        else if (res.status == 422) {
          this.SpinnerService.hide();
          this.clearCacheCommonLogout();
        }
        else if (res.StatusCode == 406) {
          if (res.Status == false) {

            this.warningMessage = res.ErrorMessage;
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getQuestionList();

          }
        }
        else if (res.StatusCode == 300) {
          if (res.Status == true) {
            this.warningMessage = res.ErrorMessage;
            this.displayWarningModal = "block";
            this.renderer.addClass(document.body, 'modal-open');
            this.addDiv();
            this.getQuestionList();
          }
        }
        else if (res.StatusCode == 401 || res.StatusCode == 400) {
          this.SpinnerService.hide();
        }
        else {
          this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
          this.displayErrorModal = "block";
          this.renderer.addClass(document.body, 'modal-open');
          this.addDiv();
        }
      });
      this.isButtonEnabled = true;
    }
  }

  editQuestionModal(questionid) {
    this.SpinnerService.show();
    this.GetAllStatus();
    this.questionservice.getQuestionId(questionid)?.then((res) => {
      if (res.StatusCode == 200) {
        this.displayEditQuestion = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();

        this.viewjsonarray = res;
        this.questionidView = this.viewjsonarray.Data.QuestionsId;
        this.questionnameView = this.viewjsonarray.Data.QuestionText;
        this.questionresponsetypeView = this.viewjsonarray.Data.ResponseType;
        this.questionstatusview = this.viewjsonarray.Data.StatusName;
        this.questionresponseoptionView = this.viewjsonarray.Data.ResponseOption;

        console.log(this.ConnectedTemplates);
        console.log(this.questionresponseoptionView);
        this.viewStatus = [{ "Status": this.viewjsonarray.Data.StatusName }];
        this.statusdata = this.viewStatus[0];
        console.log(this.statusdata);
        this.optionsarray = this.questionresponseoptionView.split(',');
        console.log(this.questionresponseoptionView.split(','));
        for (var i = 0; i < this.optionsarray.length; i++) {
          this.optionval = this.optionsarray[i];
        }
        this.editQuestionForm.patchValue({
          QuestionID: this.viewjsonarray.Data.QuestionsId,
          QuestionText: this.viewjsonarray.Data.QuestionText,
          ResponseType: this.viewjsonarray.Data.ResponseType,
          Status: this.viewjsonarray.Data.StatusName,
          ChangeNotes: this.viewjsonarray.Data.ChangeNotes,
        });
        this.questionchangenotesView = this.viewjsonarray.Data.ChangeNotes;
        this.SpinnerService.hide();
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 401 || res.StatusCode == 400) {
        this.SpinnerService.hide();
      }
      else if (res.StatusCode == 500) {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.SpinnerService.hide();
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });

  }

  closeSuccessModal() {
    this.displaySuccessModal = "none";
    this.renderer.removeClass(document.body, 'modal-open');
    let myobj = document.getElementById("demo");
    myobj?.remove();
  }

  onClearQuestion() {
    this.createQuestionForm.controls['QuestionText'].reset();
    this.createQuestionForm.controls['ResponseOption'].reset();
    this.createQuestionForm.controls['ResponseType'].reset('RadioGroup');
    this.createQuestionForm.controls['Status'].reset();
    this.createQuestionForm.controls['ChangeNotes'].reset();
    this.addResponseOptions = [];
  }

  onAddResponseOptions() {
    if (this.createQuestionForm.controls['ResponseOption'].value == '' || this.createQuestionForm.controls['ResponseOption'].value == undefined) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Option is Empty.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
    let rname = this.createQuestionForm.controls['ResponseOption'].value.trim();
    this.minresponseoptionvalue = this.addResponseOptions.length;
    console.log(this.minresponseoptionvalue);
    const Responsetype = this.createQuestionForm.get('ResponseType').value;
    console.log(Responsetype);
    for (let i = 0; i < this.addResponseOptions.length; i++) {
      this.optionNameexist = this.addResponseOptions[i];
      if (this.optionNameexist.toString().toLowerCase() === rname.toLowerCase()) {
        console.log(this.optionNameexist);
        this.secondPopupOpen = true;
        this.errorMessage = "Response Option Already Exists.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
        return false;
      }
    }

    if (rname != "" && this.addResponseOptions.length < 10) {
      this.addResponseOptions.push(rname);
      console.log(this.addResponseOptions.length);
      this.createQuestionForm.controls['ResponseOption'].reset();
    }
    else if (this.addResponseOptions.length >= 10) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Options Should Not Be More Than 10.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }

  }
  onEditResponseOptions() {
    if (this.editQuestionForm.controls['ResponseOption'].value == '' || this.editQuestionForm.controls['ResponseOption'].value == undefined) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Option is Empty.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
    let rname = this.editQuestionForm.controls['ResponseOption'].value.trim();
    this.minresponseoptionvalue = this.optionsarray.length;
    console.log(this.minresponseoptionvalue);
    console.log(this.optionsarray.length);
    const Responsetype = this.editQuestionForm.get('ResponseType').value;
    console.log(Responsetype);

    for (let i = 0; i < this.optionsarray.length; i++) {
      this.optionNameexist = this.optionsarray[i];
      if (this.optionNameexist.toString().toLowerCase() === rname.toLowerCase()) {
        console.log(this.optionNameexist);
        this.secondPopupOpen = true;
        this.errorMessage = "Response Option Already Exists.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
        return false;
      }
    }

    if (rname != "" && this.optionsarray.length < 10) {
      this.optionsarray.push(rname);
      console.log(this.optionsarray.length);
      this.editQuestionForm.controls['ResponseOption'].reset();
    }
    else if (this.optionsarray.length >= 10) {
      this.secondPopupOpen = true;
      this.errorMessage = "Response Options Should Not Be More Than 10.";
      this.displayErrorModal = "block";
      this.renderer.addClass(document.body, 'modal-open');
      this.addDiv();
      return false;
    }
  }
  onRemoveResponseOptions(value) {
    const index: number = this.addResponseOptions.indexOf(value);
    this.addResponseOptions.splice(index, 1)
    console.log(this.addResponseOptions.length);
    console.log(this.addResponseOptions);
  }
  onRemoveUpdateResponseOptions(value) {
    const index: number = this.optionsarray.indexOf(value);
    this.optionsarray.splice(index, 1)
  }

  GetAllStatus() {
    this.questionservice.getAllStatus()?.then((res) => {
      if (res.StatusCode == 200) {
        console.log(res);
        this.StatusValue = res.Data.FieldsStatus;
        console.log(this.StatusValue, 'StatusValue');
      }
      else if (res.status == 422) {
        this.SpinnerService.hide();
        this.clearCacheCommonLogout();
      }
      else if (res.StatusCode == 500) {
        this.secondPopupOpen = true;
        this.errorMessage = "Connection Error : The database seems to be offline. Please try again later.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
      else {
        this.secondPopupOpen = true;
        this.errorMessage = res.ErrorMessage ? res.ErrorMessage : "Sorry, something went wrong. To continue please logout, close the browser, login again and resume the work on this letter. If any problem occurs again in this letter, please take a full screen screenshot showing complete browser along with date time and share with IT Support.";
        this.displayErrorModal = "block";
        this.renderer.addClass(document.body, 'modal-open');
        this.addDiv();
      }
    });
  }

  minLengthWithoutLeadingSpaces(minLength: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && value.trim().length < minLength) {
        return { minLengthWithoutLeadingSpaces: true };
      }
      return null;
    };
  }

     //clear cache
     clearCacheCommonLogout() {
      this.CacheclearService.displayClearCacheWarningModal('This is an call for clear cache modal');
    }
}
