<nav id="sidebar" class="d-md-block sidebar collapsed" #sidebar [ngClass]="toggle ? 'collapsed' : ''">
    <div class=" d-flex flex-column h-100">
        <div class="sidebar-header mb-4">
            <h3 class="ps-3 pe-3"><img class="w-100" src="assets/images/changepond-white-logo.svg" alt="Logo"></h3>
            <strong class="text-center"><img src="assets/images/favicon-32x32.png" alt="Logo"></strong>
        </div>
        <ul class="nav nav-pills flex-column mb-auto align-items-center align-items-sm-start d-grid gap-2 menu"
            id="menu">
            <li class="nav-item" [hidden]="isLetterGen"><a [routerLink]="['/engagementletterdashboard']"
                    class="nav-link"
                    [ngClass]="{'active': letterdashboard === true, 'disabled-link': isDisableRouterLink}"><img
                        src="assets/images/bulk-leteer-generation.png" alt="Dashboard">
                    <span>Letter Dashboard </span></a>
                <span class="tool-tip text-white">Letter Dashboard</span>
            </li>
            <li class="nav-item" [hidden]="isbulkletter"><a
                    [ngClass]="{'active': bulkletterval === true, 'disabled-link': isDisableRouterLink}"
                    [routerLink]="['/bulkletterdashboard']" class="nav-link"><img src="assets/images/bulk-letter.png"
                        alt="Bulk Letter Generation">
                    <span>Batch Dashboard </span></a>
                <span class="tool-tip text-white">Batch Dashboard</span>
            </li>
            <li class="nav-item" [hidden]="isFields"><a [routerLink]="['/fields']" class="nav-link"
                    [ngClass]="{'active': fieldval === true, 'disabled-link': isDisableRouterLink}"><img
                        src="assets/images/fields.png" alt="Fields">
                    <span>Fields </span></a>
                <span class="tool-tip text-white">Fields</span>
            </li>
            <li class="nav-item" [hidden]="isBlocks"><a [routerLink]="['/blocks']" class="nav-link"
                    [ngClass]="{'active': blockval === true, 'disabled-link': isDisableRouterLink}"><img
                        src="assets/images/blocks.png" alt="Blocks">
                    <span>Blocks</span></a>
                <span class="tool-tip text-white">Blocks</span>
            </li>
            <li class="nav-item" [hidden]="isQuestions">
                <a [routerLink]="['/questions']" class="nav-link"
                    [ngClass]="{'active': isquestionsval === true, 'disabled-link': isDisableRouterLink}">
                    <img src="assets/images/Question.svg" alt="Questions">
                    <span>Questions</span>
                </a>
                <span class="tool-tip text-white">Questions</span>
            </li>
            <li class="nav-item" [hidden]="isTemplates"><a [routerLink]="['/templates']" class="nav-link"
                    [ngClass]="{'active': templateval === true, 'disabled-link': isDisableRouterLink}"><img
                        src="assets/images/templates.png" alt="Templates">
                    <span>Templates</span></a>
                <span class="tool-tip text-white">Templates</span>
            </li>
        </ul>
        <footer class="mt-auto">
            <span class="text-white fs-12 m-0 fw-regular">&copy;
                <script>document.write(/\d{4}/.exec(Date())[0])</script> Changepond.
            </span>
            <span class="text-white fs-12 m-0 fw-regular d-block">All Rights
                Reserved.</span>
            <span class="text-white fs-12 m-0 fw-regular d-block">Deployment Version: {{AppConfigdata}}</span>
        </footer>

    </div>
    <a class="toggle-sidebar" (click)="toogleclick()"><img src="assets/images/arrow-left.png" alt="Left-Arrow"></a>
</nav>
<header class="header collapsed" id="headertoggle" #headertoggle [ngClass]="toggle ? 'collapsed' : ''">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
        <h5 class="s-text fs-18 fw-semibold m-0" *ngIf="this.ADGroupNames != null">{{ screenNameValue }}
        </h5>
        <div class="ms-auto">
            <span class="fw-medium" *ngIf="!this.formBasedAuthLogin">{{ this.Username }} : {{ this.DashboardRoles
                }}</span>
            <span class="nameclr" *ngIf="this.formBasedAuthLogin">{{ this.DashboardRoles }}</span>
            <button class="ms-2 text-decoration-none" (click)="logoutshow()"><img src="assets/images/log-out.svg"
                    alt="Log out"></button>
        </div>
    </div>
</header>
<!-- : {{ this.DashboardRoles }} -->
<!-- Logout Modal -->
<div class="modal" tabindex="-1" id="headerLogOutModal" [ngStyle]="{'display':displayLogout}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="headerLogOutModal">
            <div class="modal-header">
                <h5 class="modal-title fs-18 fw-semibold highlight">Confirm Logout</h5>
                <button type="button" class="btn-close shadow-none" (click)="nologout()" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="s-text fw-medium mb-0">Are you sure want to Logout?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn p-btn-outline" (click)="nologout()">No</button>
                <button type="button" class="btn p-btn ms-2" (click)="logOut()">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- For no Roles -->
<div class="modal custom-modalNorole" tabindex="-1" id="noRoleModal" data-keyboard="false" data-backdrop="static"
    [ngStyle]="{'display':displayNoRole}">
    <div class="modal-dialog modal-dialog-centered dialogModal">
        <div class="modal-content" id="noRoleModal">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Access Required</h5>
            </div>
            <div class="modal-body text-center">
                <img src="./assets/images/warning.png" class="mb-3" alt="warning">
                <p class="fs-16">You do not have access to this application as you are not mapped with any of the Azure
                    Active Directory groups. Please contact the Citrin Helpdesk.</p>
            </div>
        </div>
    </div>
</div>
<!-- warning Modal -->
<div class="modal" tabindex="-1" id="cacheclear" [ngStyle]="{'display':displayClearCacheWarningModal}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" id="cacheclear">
            <div class="modal-header warning-modal">
                <h5 class="modal-title fw-semibold highlight fs-18 text-white">Info</h5>
            </div>
            <div class="modal-body text-center">
                <p></p>
                <p class="fs-16">{{warningMessage}}</p>
                <button type="button" (click)="cacheClearLogout()" class="warning-btn"
                    data-bs-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>
<div [innerHTML]="trustedHtml"></div>