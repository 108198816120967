<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
	<p style="color: white">Please Wait. </p>
</ngx-spinner> -->
<!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium" fullScreen="true">
	<p class="loading">Please Wait.</p>
</ngx-spinner> -->
<nav class="navbar navbar-vertical sidebar_main push-left" id="maintoggle" #maintoggle
	[ngClass]="toggle ? 'push-left' : ''">
	<div class="container-fluid px-0">
		<div class="row">
			<div class="col-12">
				<!-- Brand -->
				<a class="navbar-brand">
					<img src="assets/images/logo.png" class="main_logo mx-auto" alt="logo" />
				</a>
				<a (click)="toogleclick()" class="navbar-toggler d-md-none mobile_toggler"> <i class="fas fa-times"
						aria-hidden="true"></i></a>
				<!-- side menu -->
				<ul class="navbar-nav" *ngIf="this.isivisibble == false">

					<!-- {{ this.isivisibble }}  -->

					<li class="nav-item" [hidden]="isLetterGen">
						<a class="nav-link active">
							<img src="assets/images/template.svg" class="" alt="temp" />Letter Generation
						</a>
					</li>
					<li class="nav-item" [hidden]="isFields">
						<a class="nav-link" [routerLink]="['/field']"><img src="assets/images/field.svg" class=""
								alt="field" />Fields</a>
					</li>
					<li class="nav-item" [hidden]="isBlocks">
						<a class="nav-link" [routerLink]="['/block']">
							<img src="assets/images/block.svg" class="" alt="block" />Blocks
						</a>
					</li>
					<li class="nav-item" [hidden]="isQuestions">
						<a class="nav-link" [routerLink]="['/question']">
							<img src="assets/images/question.svg" class="" alt="ques" />Questions
						</a>
					</li>
					<li class="nav-item" [hidden]="isTemplates">
						<a class="nav-link" [routerLink]="['/template']"><img src="assets/images/template.svg" class=""
								alt="temp" />Templates</a>
					</li>
					<!-- <li class="nav-item" [hidden]="isPageSettings">
						<a class="nav-link " [routerLink]="['/pagesettings']">
						  <img src="assets/images/settings.png" class="" alt="psettings" />Page Settings
						</a>
					  </li>
					  <li class="nav-item" [hidden]="isLetterhead">
						<a class="nav-link " [routerLink]="['/letterhead']"><img src="assets/images/letter.svg" class="" alt="gear" />Letterheads</a>
					  </li>
					  <li class="nav-item" [hidden]="isWatermark">
						<a class="nav-link " [routerLink]="['/watermark']"><img src="assets/images/settings.png" class="" alt="gear" />Watermarks</a>
					  </li> -->
					<li class="nav-item" [hidden]="isReports">
						<a class="nav-link" [routerLink]="['/report']"><img src="assets/images/pagesetting2.png"
								class="" alt="gear" />Reports</a>
					</li>
					<li class="nav-item" [hidden]="isUploadSignature">
						<a class="nav-link" [routerLink]="['/uploadsignature']"><img src="assets/images/settings.png"
								class="" alt="gear" />Upload Signature</a>
					</li>
				</ul>
				<ul class="navbar-nav" *ngIf="this.isivisibble == true">

					<!-- {{ this.isivisibble }}  -->

					<li class="nav-item">
						<a class="nav-link active"><img src="assets/images/template.svg" class="" alt="temp" />Letter
							Generation</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" [routerLink]="['/report']"><img src="assets/images/pagesetting2.png"
								class="" alt="gear" />Reports</a>
					</li>
					<li class="nav-item" [hidden]="isUploadSignature">
						<a class="nav-link" [routerLink]="['/uploadsignature']"><img src="assets/images/settings.png"
								class="" alt="gear" />Upload Signature</a>
					</li>
				</ul>
			</div>

			<div class="col-12 nav_copyright">
				<p>
					&copy; 2021 Berdon LLP.<br />
					Engage v2.0
				</p>
			</div>
		</div>
	</div>
</nav>

<section class="main_content push-left" id="maintoggledrop" #maintoggledrop [ngClass]="toggle ? 'push-left' : ''">
	<ngx-spinner size="medium" [type]="typeSelected"></ngx-spinner>
	<div class="container-fluid">
		<div class="mobile-header show-for-mobile d-flex d-md-none">
			<div class="row m-0 w-100">
				<div class="col-8">
					<a class="navbar-brand">
						<img src="assets/images/logo.png" class="main_logo mx-auto" alt="logo" />
					</a>

				</div>
				<div class="col-4 text-right">
					<a class="navbar-toggler" (click)="toogleclick()"><i class="fa fa-bars" aria-hidden="true"></i></a>
				</div>
				<div (clickOutside)="onClickedOutside($event)"
					class="col-12 col-sm-6 col-md-6 user_profile text-left dropdown" [ngClass]="dropdown ? 'show' : ''">
					<span class="nameclr" *ngIf="this.ADGroupNames != null">{{ this.Username }} : {{ this.Roles
						}}</span>
					<span class="nameclr" *ngIf="this.ADGroupNames == null">{{ this.Username }} : Initiator</span>
					<a class="btn ml-1 logout_main" (click)="logoutshow()"><img src="assets/images/logout-white.png"
							class="logout_icon" title="Logout" alt="logout_icon" /></a>
					<!-- <ul class="dropdown-menu User_profile_dropdown" (click)="toogleDropdown()" [ngClass]="dropdown ? 'show' : ''">
						<li><a class="" >My Profile</a></li>
						<li><a class="" >Settings</a></li>
						<li><a class="" (click)="logOut()" >Logout</a></li>
					</ul> -->
				</div>
			</div>
		</div>



		<div class="row header_body d-md-flex d-none">
			<div class="col-12 col-sm-5 col-md-5 header_title">
				<a class="navbar-toggler" (click)="toogleclick()"><i class="fa fa-bars" aria-hidden="true"></i></a>
				<h5>Letter Generation</h5>
			</div>
			<div (clickOutside)="onClickedOutside($event)"
				class="col-12 col-sm-7 col-md-7 user_profile text-right dropdown" [ngClass]="dropdown ? 'show' : ''">
				<span class="nameclr" *ngIf="this.ADGroupNames != null">{{ this.Username }} : {{ this.DashboardRoles
					}}</span>
				<span class="nameclr" *ngIf="this.ADGroupNames == null">{{ this.Username }} : Initiator</span>
				<a class="btn ml-1 logout_main" (click)="logoutshow()"><img src="assets/images/logout-white.png"
						class="logout_icon" title="Logout" alt="logout_icon" /></a>
				<!-- <ul class="dropdown-menu User_profile_dropdown" (click)="toogleDropdown()" [ngClass]="dropdown ? 'show' : ''">
                    <li><a class="" >My Profile</a></li>
                    <li><a class="" >Settings</a></li>
                    <li><a class="" (click)="logOut()" >Logout</a></li>
                </ul> -->
			</div>
		</div>

		<div class="row content_body">
			<div class="col-12 col-sm-12 col-md-12">
				<div class="row mb-3 d-flex">
					<div class="col-12 col-sm-3 col-md-3 d-flex justify-content-start">
						<div class="">
							<h5 class="new_title mb-0 mt-2"><span class="btn btn-primary" id="mylettertoggle"
									#mylettertoggle (click)="getMyInitiator()">My Letters</span>|<span
									id="alllettertoggle" #alllettertoggle (click)="getAllInitiator()">All Letters</span>
							</h5>
						</div>
					</div>
					<div
						class="col-12 col-sm-9 col-md-9  d-flex  flex-wrap flex-md-nowrap justify-content-center justify-content-md-end">
						<div class="big_search">
							<!-- <input type="text" class="form-control" placeholder="Search..."> (keyup)="applyFilter()" -->
							<form [formGroup]="searchForm">
								<!-- <input pInputText type="text" [(ngModel)]="SearchText"  formControlName="GlobalSearch" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Global Search"/> -->
							</form>
							<!-- <button type="submit" aria-label="search" (click)="applyFilter()"  style="outline: none;"><img
										src="assets/images/search-glass.svg" class="img-fluid" alt="close" /></button> -->
						</div>

						<!-- <p-autoComplete [suggestions]="output" (completeMethod)="search($event)"
							placeholder="Search for genres"></p-autoComplete> -->


						<!-- <button (click)="openCreateModal()" class="btn btn-primary action_btn mb-2 mb-md-0 ml-2 ml-md-3 "  ><img src="assets/images/plus.svg" class="img-fluid" alt="plus" />Create</button> -->
						<button class="btn btn-secondary action_btn mb-2 mb-md-0 ml-2 ml-md-3 "
							(click)="getrefreshbulkstatus()"><img src="assets/images/plus.svg" class="img-fluid"
								alt="plus" />RefreshLetterStatus</button>
						<a class="btn btn-secondary action_btn mb-2 mb-md-0 ml-2 ml-md-3 "
							(click)="bulklettergen()"><img src="assets/images/plus.svg" class="img-fluid"
								alt="plus" />BulkLetterGeneration</a>
						<!-- <a href="/createbulkgendashboard">submit</a> -->
						<!-- <button [hidden]="isReviewButton" class="btn btn-primary action_btn mb-2 mb-md-0 ml-2 disabled" #reviewbtn (click)="ReviewPagenew()" [disabled]="isReadOnlyData" ><img
									src="assets/images/review.svg" class="img-fluid" alt="plus" />Review</button> -->

						<button class="btn btn-primary action_btn mb-2 mb-md-0 ml-2" [hidden]="isRollOverbtn"
							(click)="rollovercheck()" [disabled]="isRollOnly"><img src="assets/images/sign-in.svg"
								class="img-fluid" alt="plus" />Roll Over</button>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12 tempcreate_sec" id="template_sec">
						<!-- <button class="btn btn-link collapsed mb-2 d-block w-100 text-dark  black-text border text-decoration-none" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" 
				aria-controls="collapseOne" (click)="collapseFilter()">Search or Filter
        </button> -->
						<div id="collapseOne" #collapseOne class="collapse" aria-labelledby="headingOne"
							data-parent="#template_sec">
							<form class="form-horizontal" [formGroup]="initiatorForm">
								<div class="row overlap_remove">
									<div class="col-12 col-sm-6 col-md-6">
										<div class="row">

											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Engagement
														Letter ID </label>
													<ng-select [searchable]="true" formControlName="EngagementLetterId"
														[clearable]="false" [items]="EngagementLetterIds"
														bindValue="EngagementLetterId" [virtualScroll]="true"
														bindLabel="EngagementLetterIdValue" placeholder="Select"
														[(ngModel)]="model.engagementLetterId">
													</ng-select>
												</div>
											</div>


											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Engagement Letter
														Name</label>
													<ng-select [searchable]="true"
														formControlName="EngagementLetterName" [clearable]="false"
														[items]="engageLetterName" bindValue="EngagementLetterId"
														[virtualScroll]="true" bindLabel="EngagementLetterName"
														placeholder="Select" [(ngModel)]="model.engagementLetter"
														focusOnce>
													</ng-select>
												</div>
											</div>

											<div class="col-md-6">
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="control-label" for="displayname">Tax
																Year</label>
															<ng-select [searchable]="false" formControlName="YearId"
																[clearable]="false" [items]="yearsList"
																bindValue="YearId" [virtualScroll]="true"
																bindLabel="Year" placeholder="Select"
																[(ngModel)]="model.selectedyear">
															</ng-select>
														</div>
													</div>
													<div class="col-md-6">
														<div class="form-group">
															<label class="control-label"
																for="displayname">Department</label>
															<ng-select [searchable]="false"
																formControlName="DepartmentId" [clearable]="false"
																[items]="departmentList" bindValue="DepartmentId"
																[virtualScroll]="true" bindLabel="Name"
																placeholder="Select" [(ngModel)]="model.selectedept">
															</ng-select>
														</div>
													</div>
												</div>

											</div>

											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Type</label>
													<ng-select [searchable]="false" formControlName="EngageTypeId"
														[clearable]="false" [items]="engageType" bindValue="Id"
														[virtualScroll]="true" bindLabel="Name" placeholder="Select"
														[(ngModel)]="model.selectedType">
													</ng-select>
												</div>
											</div>



										</div>
									</div>
									<div class="col-12 col-sm-6 col-md-6">
										<div class="row">
											<!-- <div class="col-md-6">
											<div class="form-group">
												<label class="control-label" for="displayname">Year</label>
												<ng-select [searchable]="false" formControlName="YearId"
													[clearable]="false" [items]="yearsList" bindValue="YearId"
													[virtualScroll]="true" bindLabel="Year" placeholder="Select"
													[(ngModel)]="model.selectedyear">
												</ng-select>
											</div>
										</div> -->

											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Client
													</label>
													<ng-select [items]="this.clientnamess" formControlName="Client"
														bindLabel="ClientName" [(ngModel)]="model.selectedDatatype"
														placeholder="Select" required [searchable]="true"
														[clearable]="false">
													</ng-select>
												</div>
											</div>



											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Responsible
														Person</label>
													<ng-select [items]="this.ResponsiblePersonsName"
														bindLabel="ResponsiblePerson"
														[(ngModel)]="model.selectedresperson"
														formControlName="ResponsiblePerson" placeholder="Select"
														required [searchable]="true" [clearable]="false">
													</ng-select>
												</div>
											</div>



											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Engagement
														Partner</label>
													<ng-select [items]="this.SigningPartner" bindLabel="SigningPartner"
														[(ngModel)]="model.selectedengpartner"
														formControlName="EngagementPartner" placeholder="Select"
														required [searchable]="true" [clearable]="false">
													</ng-select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="control-label" for="displayname">Signing
														Partner</label>
													<ng-select [items]="this.SigningPartner" bindLabel="SigningPartner"
														[(ngModel)]="model.selectedsignperson"
														formControlName="SigningPartner" placeholder="Select" required
														[searchable]="true" [clearable]="false">
													</ng-select>
												</div>
											</div>

										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-12 col-sm-6 col-md-9">
										<div class="row">
											<div class="col-md-4 dropdown_top">
												<div class="form-group">
													<label class="control-label" for="displayname"> Status</label>
													<ng-select [searchable]="false" formControlName="StatusID"
														dropdownPosition="top" [clearable]="false" [items]="Statuslist"
														bindValue="StatusID" [virtualScroll]="true" bindLabel="Status"
														placeholder="Select" [(ngModel)]="model.selectedstatus">
													</ng-select>

												</div>
											</div>
											<div class="col-md-4 dropdown_top">
												<div class="form-group">
													<label class="control-label" for="displayname">Saved Filters</label>
													<ng-select [searchable]="false" dropdownPosition="top"
														formControlName="FilterId" [clearable]="false"
														[items]="savedInitiatorFilterList" bindValue="FilterId"
														[virtualScroll]="true" bindLabel="FilterName"
														placeholder="Select" [(ngModel)]="model.selectedFilter">
													</ng-select>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="control-label" for="FilterName">Save
														Filter</label>
													<div class="row no-gutters">
														<div class="col-md-8">

															<input id="FilterName" autocomplete="off" minlength="1"
																(keyup.space)="$event.preventDefault();"
																name="FilterName" formControlName="FilterName"
																#FilterName1 type="text" placeholder="Filter Name"
																class="form-control input-md" />
															<!-- <ng-container
															*ngFor="let validation of validation_messages.FilterName">
															<div class="errorvl errorvlfname "
																*ngIf="initiatorForm.get('FilterName').hasError(validation.type) && (initiatorForm.get('FilterName').dirty || initiatorForm.get('FilterName').touched)">
																<label class="errorcolor hide"> {{ validation.message
																	}}</label>
															</div>
														</ng-container> -->
														</div>
														<div class="col-md-4">
															<button (click)="SaveFilter()"
																class="btn btn-primary action_btn mt-2 mt-md-0 ml-3"
																data-dismiss="modal"
																[disabled]="!initiatorForm.get('FilterName').valid">Save</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-6 col-md-3">
										<div class="row">
											<div class="col-md-12 text-right mt-4">
												<a class="btn btn-primary mb-2 mb-md-0 mx-2" (click)="onClear()"
													data-dismiss="modal">Clear</a>
												<a (click)="Search()"
													class="mb-2 mb-md-0 mx-2 btn btn-primary action_btn ml-3"
													data-dismiss="modal">Search</a>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-12 col-md-12">
						<div class="col-12 col-sm-12 col-md-12">
							<h1><label class="control-label"> Bulk Letter Batch List </label></h1>
							<div #tooltipbottomtxt class="table-responsive initiator_table tooltipmain">
								<p-table #dt [columns]="cols" [value]="this.InitiatorList"
									styleClass="p-datatable-gridlines" dataKey="id" [resizableColumns]="true"
									sortMode="multiple" [scrollable]="true" scrollHeight="1000px"
									[showCurrentPageReport]="true" [paginator]="true" [rows]="20"
									currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
									<ng-template pTemplate="header" let-columns>
										<tr>
											<th *ngFor="let col of columns">
												{{col.header}}
											</th>
										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-rowData let-columns="columns">
										<tr>
											<td *ngFor="let col of columns">
												{{rowData[col.field]}}
											</td>
										</tr>
									</ng-template>

									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="12" style="text-align: center;">No
												Records Found</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>



<!-- Delete Modal start here -->
<div class="modal" id="deletetemplate" [ngStyle]="{'display':displayDelete}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" class="close mx-3 mt-2" (click)="onCloseDelete()"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Are you sure want to delete this Engagement Letter?</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="onDeleteInitiator()" class="btn btn-primary mx-2"
										data-dismiss="modal">Yes</a>
									<a class="btn btn-secondary mx-2" (click)="onCloseDelete()"
										data-dismiss="modal">No</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="cancelfield" [ngStyle]="{'display':displayDatabaseFailear}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" (click)="ConnectionError()" class="close mx-3 mt-2"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Connection Error : The database seems to be offline. Please try again later.
									</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="ConnectionError()" class="btn btn-primary mx-2"
										data-dismiss="modal">Ok</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" id="" [ngStyle]="{'display':displayLogout}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" class="close mx-3 mt-2" (click)="nologout()" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Are you sure want to Log out ?</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="logOut()" class="btn btn-primary mx-2" data-dismiss="modal">Yes</a>
									<a class="btn btn-secondary mx-2" (click)="nologout()" data-dismiss="modal">No</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Document Approval Override start here -->
<!-- <div class="modal" id="viewinitiator" [ngStyle]="{'display':display}">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="model-action text-right">
                <a (click)="onCloseHandled()" class="action_icon mt-2 mx-3" data-dismiss="modal"><img
                        src="assets/images/close.svg" class="img-fluid" alt="close" /></a>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12">
							<h6 class="model_title"><b>Manage Document Approval Override</b></h6>
                            <form class="form-horizontal" [formGroup]="initiatorEditForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label" for="Initiator">Assigned For</label>
                                             
											 <ng-select [items]="this.SigningPartners" formControlName="Signingpartners" bindLabel="SigningPartners" 
											 [clearable]="false" [(ngModel)]="this.SigningPartners" [readonly]="isReadOnly">
										 </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="control-label" for="Override">Override To<span>*</span></label>
											<ng-select [items]="this.ResponsiblePersonsName" bindLabel="ResponsiblePersonsName"
													[(ngModel)]="selectedResponsiblePersonsName" 	formControlName="ResponsiblePersonsName" 	placeholder="Select" required [searchable]="false" [clearable]="false">
													</ng-select>
                                        </div>
                                    </div>
                                </div>
								<div class="row">
                                    <div class="col-sm-12 text-center">
                                        <a (click)="onCloseHandled()" class="btn btn-secondary mx-2"
                                            data-dismiss="modal">Cancel</a>
                                        <button type="button"
                                            (click)=" SaveOverRide()"  [disabled]="!initiatorEditForm.valid" class="btn btn-primary mx-2 ">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Engagement letter History start here-->
<div class="modal" id="viewinitiator" [ngStyle]="{'display':display}">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" style="width: 750px;">
		<div class="modal-content">
			<div class="model-action text-right">
				<a (click)="onCloseHandled()" class="action_icon mt-2 mx-3" data-dismiss="modal"><img
						src="assets/images/close.svg" class="img-fluid" alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-sm-12">
							<h6 class="mb-3"><b>Manage Letter Reassigning</b></h6>
						</div>
						<div class="col-12 col-sm-12 col-md-12 new_label_structure mb-2">
							<p><strong>Engagement Letter ID</strong><span>: {{ this.overrideLetterId }}</span>
							</p>
							<p>
								<strong>Engagement Letter Name</strong><span>: {{ this.engName }}</span>
							</p>
							<p>
								<strong>Current Letter Status</strong><span>: {{ this.Statusname }}</span>
							</p>
						</div>
						<!-- <div class="col-12 col-sm-12 col-md-12">
							<h6 class="model_title"><b>Manage Letter Reassigning</b></h6> -->
						<!-- Manage Document Approval Override -->
						<div class="col-12 col-sm-12 col-md-12">
							<h6 class="model_title" style="margin-left: 155px;"><b>Move Letter Now to Another Person

								</b></h6>
							<form class="form-horizontal" [formGroup]="initiatorEditForm">
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<span class="control-label" for="Initiator"
												style="margin-left: 60px;">Currently Assigned to ({{this.Rolestatus
												}})</span>

											<ng-select [items]="this.AssignedFor" id="assignedfor"
												formControlName="Signingpartners" bindLabel="SigningPartners"
												[clearable]="false" [(ngModel)]="this.AssignedFor"
												[readonly]="isReadOnly" style="
											 width: 206px; margin-left: 60px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6" *ngIf="this.IsAllusergrouparr == 'true'">
										<div class="form-group">
											<span class="control-label" for="Override"> Move To ({{this.Rolestatus
												}})</span>
											<ng-select [items]="this.AllUsers" bindLabel="ResponsiblePersonsName"
												(click)="onChangeoveride($event.target.value)"
												[(ngModel)]="selectedResponsiblePersonsName" [virtualScroll]="true"
												formControlName="ResponsiblePersonsName"
												[placeholder]="isSigningpartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width: 208px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6" *ngIf="this.IsRpOveride == 'true'">
										<div class="form-group">
											<span class="control-label" for="Override">Move To ({{this.Rolestatus }})
											</span>
											<ng-select [items]="this.RPOverideToMembers"
												bindLabel="ResponsiblePersonsName"
												(click)="onChangeoveride($event.target.value)"
												[(ngModel)]="selectedResponsiblePersonsName"
												formControlName="ResponsiblePersonsName"
												[placeholder]="isSigningpartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width: 208px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6" *ngIf="this.IsSpOveride == 'true'">
										<div class="form-group">
											<span class="control-label" for="Override">Move To ({{this.Rolestatus }})
											</span>
											<ng-select [items]="this.SPOverideToMembers"
												bindLabel="ResponsiblePersonsName"
												(click)="onChangeoveride($event.target.value)"
												[(ngModel)]="selectedResponsiblePersonsName"
												formControlName="ResponsiblePersonsName"
												[placeholder]="isSigningpartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width: 208px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6" *ngIf="this.IsWpOveride == 'true'">
										<div class="form-group">
											<span class="control-label" for="Override">Move To ({{this.Rolestatus
												}})</span>
											<ng-select [items]="this.WPOverideToMembers"
												bindLabel="ResponsiblePersonsName"
												(click)="onChangeoveride($event.target.value)"
												[(ngModel)]="selectedResponsiblePersonsName"
												formControlName="ResponsiblePersonsName"
												[placeholder]="isSigningpartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width: 208px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6" *ngIf="this.IsQcOveride == 'true'">
										<div class="form-group">
											<span class="control-label" for="Override">Move To ({{this.Rolestatus
												}})</span>
											<ng-select [items]="this.QCOverideToMembers"
												bindLabel="ResponsiblePersonsName"
												(click)="onChangeoveride($event.target.value)"
												[(ngModel)]="selectedResponsiblePersonsName"
												formControlName="ResponsiblePersonsName"
												[placeholder]="isSigningpartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width: 208px;">
											</ng-select>
										</div>
									</div>
								</div>
								<h6 class="model_title" style="margin-left: 155px;"><b>Change Roles to Another Person
									</b></h6>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label" for="Initiator"
												style="margin-left: 60px;">Current Initiator:</label>

											<ng-select [items]="this.Createdby" id="assignedforinitiator"
												formControlName="Initiator" [(ngModel)]="this.Createdby" bindLabel=""
												[clearable]="false" [readonly]="isReadOnly" style="
											 width: 206px; margin-left: 60px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<span class="control-label" for="Override">Change Initiator to:</span>
											<ng-select [items]="this.AllUsers" bindLabel="InitiatorAssigned"
												(click)="onChangeoverideInitiator($event.target.value)"
												formControlName="InitiatorAssigned"
												[(ngModel)]="this.selectedInitiatorAssigned"
												[readonly]="isReadOnlyInitiator" placeholder="Select"
												[virtualScroll]="true" required [searchable]="true" [clearable]="false"
												style="width: 208px;">
											</ng-select>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label" for="Initiator"
												style="margin-left: 60px;">Current RP: </label>

											<ng-select [items]="this.ResponsiblePersonAssignedName"
												formControlName="ResponsiblePerson"
												[(ngModel)]="this.ResponsiblePersonAssignedName" bindLabel=""
												[clearable]="false" [readonly]="isReadOnly" style="
											 width: 206px; margin-left: 60px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<span class="control-label" for="Override">Change RP to:</span>
											<ng-select [items]="this.RPOverideToMembers" id="assignedforrp"
												bindLabel="ResponsiblePersonAssigned"
												(click)="onChangeoverideRP($event.target.value)"
												formControlName="ResponsiblePersonAssigned"
												[(ngModel)]="this.selectedResponsiblePersonAssigned"
												[readonly]="isReadOnlyRP"
												[placeholder]="isResponsiblepartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width: 208px;">
											</ng-select>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label class="control-label" for="Initiator"
												style="margin-left: 60px;">Current SP:</label>

											<ng-select [items]="this.SigningPartnerAssignedName"
												formControlName="SigningPartner"
												[(ngModel)]="this.SigningPartnerAssignedName" bindLabel=""
												[clearable]="false" [readonly]="isReadOnly" style="
												 width: 206px; margin-left: 60px;">
											</ng-select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<span class="control-label" for="Override">Change SP to:</span>
											<ng-select [items]="this.SPOverideToMembers" id="assignedforsp"
												bindLabel="SigningPartnerAssigned"
												(click)="onChangeoverideSP($event.target.value)"
												formControlName="SigningPartnerAssigned"
												[(ngModel)]="this.selectedSigningPartnerAssigned"
												[readonly]="isReadOnlySP"
												[placeholder]="isSigningpartner? ' ': 'Select'" required
												[searchable]="true" [clearable]="false" style="width:208px;">
											</ng-select>
										</div>
									</div>
								</div>
								<div class="image_file_notes"
									*ngIf='(this.Rolestatus=="SP" ||  this.onchangespsig=="SP")  && this.selectedSigningPartnerAssigned!=null && this.partnernumber==undefined'>
									<p><b> Note: The selected SP<span>: {{ this.selectedSigningPartnerAssigned }}'s
											</span>phone number is not available. Hence the main firm number (212)
											832-0400 will be replaced and if required, it can be edited before sending
											the letter to client for signing</b></p>
								</div>


								<div class="row mt-5">
									<div class="col-sm-12 text-center">
										<a (click)="onCloseHandled()" class="btn btn-secondary mx-2"
											data-dismiss="modal">Cancel</a>
										<button #disabledbtn type="button" (click)="spchangechecking()"
											class="btn btn-primary mx-2 disabled">Submit</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--upload Modal start here -->
<div class="modal" id="upload_doc" [ngStyle]="{'display':displayupload}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<form class="form-horizontal" [formGroup]="uploadForm">
							<div class="col-12 col-sm-12 col-sm-12">
								<h6 class=""><b>Upload Document</b></h6>

								<div class="form-group">
									<label class="control-label" for="fieldname">Attachment file</label>
									<div class="">

										<input type="file" formControlName="uploadDocument" accept=".pdf,.doc,.docx"
											(change)="fileChange($event)" class="" /><br>
										<div class="image_file_notes" style="margin-bottom:21px;padding-top: 10px">
											<p><strong>Note: </strong> PDF file should be less than 10MB in file size
											</p>
											<p>Please contact Helpdesk to upload files more than 10MB</p>
										</div>
									</div>
								</div>
							</div>
						</form>
						<div class="col-sm-12 text-right">
							<a (click)="cancelupload()" class="btn btn-third mx-2" data-dismiss="modal">Cancel</a>
							<a (click)="UploadPdfDownload()" class="btn btn-secondary mx-2"
								data-dismiss="modal">Submit</a>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- upload Modal End here -->

<div class="modal" id="viewrollover" [ngStyle]="{'display':displayrollover}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" class="close mx-3 mt-2" (click)="onCloserollover()"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Are you sure want to force and change the pending letters to completed status ?
									</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="onrolloverclick()" class="btn btn-primary mx-2"
										data-dismiss="modal">Yes</a>
									<a class="btn btn-secondary mx-2" (click)="onCloserollover()"
										data-dismiss="modal">No</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="viewcancelsigning" [ngStyle]="{'display':displaycancelsigning}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" class="close mx-3 mt-2" (click)="onClosecancelsigning()"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Are you sure want to cancel the signing process?</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="cancelsigning()" class="btn btn-primary mx-2"
										data-dismiss="modal">Yes</a>
									<a class="btn btn-secondary mx-2" (click)="onClosecancelsigning()"
										data-dismiss="modal">No</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="rollOverIcon" [ngStyle]="{'display':displayrollOverIcon}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" class="close mx-3 mt-2" (click)="onCloserollOverIcon()"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Are you sure want to Rollover this engagement letter?</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="rollover()" class="btn btn-primary mx-2" data-dismiss="modal">Yes</a>
									<a class="btn btn-secondary mx-2" (click)="onCloserollOverIcon()"
										data-dismiss="modal">No</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Engagement letter History start here-->
<div class="modal" id="historyletter" [ngStyle]="{'display':displayhistory}">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-sm-12">
							<h6 class="mb-3"><b>Engagement Letter History</b></h6>
						</div>
						<div class="col-12 col-sm-12 col-md-12 new_label_structure mb-2">
							<p><strong>Engagement Letter ID</strong><span>: {{ this.EngageletterId }}</span>
							</p>
							<p>
								<strong>Engagement Letter Name</strong><span>: {{ this.EngageLetterName }}</span>
							</p>
						</div>
						<div class="col-12 col-sm-12 col-md-12">
							<div class="table-responsive Engagementtable letterhistory">
								<p-table #dt [value]="this.EngagementLetterList" styleClass="p-datatable-gridlines"
									dataKey="id">
									<ng-template pTemplate="colgroup">
										<colgroup>
											<col style="width:25%">
											<col style="width:23%">
											<col style="width:10%">
											<col style="width:30%">
											<col style="width:25%">
										</colgroup>
									</ng-template>
									<ng-template pTemplate="header">
										<tr>
											<th>Status</th>
											<th>Employee Name</th>
											<th>Ver</th>
											<th>Last Modified</th>
											<th>Action(s)</th>

										</tr>
									</ng-template>
									<ng-template pTemplate="body" let-engagementletter>
										<tr>
											<td>{{engagementletter.Action}}</td>
											<td>{{engagementletter.EditedBy}}</td>
											<td>{{engagementletter.Version}}</td>
											<td>{{engagementletter.LastModified}}</td>

											<td class="icon_image">
												<!-- (click)="settingsPreviewFinal(engagementletter?.Preview)" -->
												<button data-toggle="modal"
													class="view_btn pointer tooltip-new .tooltip-btnclk" appNodblclick
													(debounceClick)="log()" [debounceTime]="700"
													data-target="#editletter"
													(click)="settingsPreviewFinal(engagementletter?.Preview)"
													[hidden]="engagementletter.Action=='Draft' || engagementletter.Action=='Completed' || engagementletter.Action=='SP Signed The Letter'|| engagementletter.Action=='Cancelled the Signing Request' ||  engagementletter.Action=='With Client For Manual Signing'  || engagementletter.Action=='With Client For E-Signing'||this.delegatedvalcheck ==engagementletter.Action || engagementletter.Action=='Client declined the Signing'"><span><img
															src="assets/images/eye_26.svg" class="eye_icon img-fluid"
															alt="close" /><span class="tooltiptext">Preview
															Letter</span></span></button>
												<!-- (click)="downloadDocFile(engagementletter.EngagementLetterId)" -->
												<!-- <button data-toggle="modal" id="download" class="view_btn pointer tooltip-new .tooltip-btnclk"><span><img
															src="assets/images/download_26.svg"
															[hidden]="((((this.Roles == 'Responsible Person' || this.Roles == 'Initiator' || this.Roles == 'Word Processing Team' || this.Roles == 'QC Team') &&  this.newRoles.length==1) && this.Status != 'Completed' && this.Status != 'Rolled Over')|| this.isNewRoles || engagementletter.Action ==='Draft' || pdfversion!==engagementletter.Version)"
															 (click)="bindHangingSentenceSave()" 																													
															class="eye_icon img-fluid" alt="eye" /><span class="tooltiptext">Download Letter</span></span></button> -->
												<button data-toggle="modal" id="download"
													class="view_btn pointer tooltip-new .tooltip-btnclk margin-icon-align"
													[hidden]="((((this.Roles == 'Initiator' || this.Roles == 'QC Team') &&  this.newRoles.length==1) && this.Status != 'Completed' && this.Status != 'Rolled Over')|| this.isNewRoles || engagementletter.Action ==='Draft' || pdfversion!==engagementletter.Version)"
													(click)="downloadDocFile(engagementletter.EngagementLetterId)"
													data-toggle="modal" data-target="#viewwizard">
													<span><img src="assets/images/latest_download_icon.svg"
															class="eye_icon img-fluid" /><span
															class="tooltiptext">Download Letter</span></span>
												</button>


												<!-- "downloadDocFileForPopup(engagementletter.EngagementLetterId)"	 -->
												<!-- <button data-toggle="modal" class="view_btn pointer tooltip-new" #historybtn [hidden]="pdfversion!==engagementletter.Version" (click)="questionPopup()" class="view_btn pointer" data-toggle="modal"
															data-target="#viewwizard">
															<span><img src="assets/images/ques_info_icon.svg"
																class="eye_icon img-fluid" /><span class="tooltiptext">View Wizard Responses</span></span>
														</button> -->
												<button data-toggle="modal"
													class="view_btn pointer tooltip-new .tooltip-btnclk" #historybtn
													[hidden]="pdfversion!==engagementletter.Version"
													(click)="questionPopup()" data-toggle="modal"
													data-target="#viewwizard">
													<span><img src="assets/images/ques_info_icon.svg"
															class="eye_icon img-fluid" /><span class="tooltiptext">View
															Wizard Responses</span></span>
												</button>
												<button data-toggle="modal"
													class="view_btn pointer tooltip-new .tooltip-btnclk" #historybtn
													[hidden]="(engagementletter.Action !='Client declined the Signing')"
													(click)="declinePopup()" data-toggle="modal"
													data-target="#viewwizard">
													<span><img src="assets/images/messag_icon.svg"
															class="eye_icon img-fluid" /><span
															class="tooltiptext">Declined reason</span></span>
												</button>
												<button data-toggle="modal"
													class="view_btn pointer tooltip-new .tooltip-btnclk" #historybtn
													[hidden]="(engagementletter.Action !='Reassigned')"
													(click)="reassignedPopup(engagementletter?.EngagementLetterId,engagementletter.Version)"
													data-toggle="modal">
													<span><img src="assets/images/log-file-format-svgrepo-com.svg"
															class="eye_icon img-fluid" /><span
															class="tooltiptext">Log</span></span>
												</button>
											</td>
										</tr>
									</ng-template>
									<ng-template pTemplate="emptymessage">
										<tr>
											<td colspan="12" style="text-align: center;">No
												Records Found</td>
										</tr>
									</ng-template>
								</p-table>
							</div>
						</div>
						<div class="col-sm-12 text-right mt-3">
							<a (click)="onCloseHistory()" class="btn btn-secondary mx-2" data-dismiss="modal">Close</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Final Preview here -->
<div class="modal" id="editletter" [ngStyle]="{'display':displaysettingsPreview}">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content" style="
		height: 100%;
	">
			<div class="model-action">
				<button type="button" (click)="closePreview()" class="close mx-3 mt-2"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid" style="height:100%;">
					<div class="row" style="height: 100%;">
						<div class="col-12 col-sm-12 col-md-12" style="height: 100%;">
							<div class="" style="height: 100%;">
								<div class="preview_body" style="height: 100%;">
									<!-- <div class="preview_inner preview_doc remove_shadow" id="preview_inner_letter"
										#preview_inner_letter> -->
									<!-- <pdf-viewer [src]="pdfSrc" [stick-to-page]="true" [show-borders]="true"
										[render-text]="true"
										style="display: block;width:100%;height:calc(100vh - 180px)"
							></pdf-viewer> -->
									<iframe id="stream_iframe" [src]="pdfSrc" width="100%" webkitallowfullscreen
										mozallowfullscreen allowfullscreen style="height:100%;"></iframe>
									<!-- </div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- Preview Modal strat here -->
<div class="modal" id="pagetemplate" [ngStyle]="{'display':displayhide}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="model-action text-right">
				<a class="action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
						alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<h5 class="model_title">Preview Template</h5>
						</div>
					</div>
					<div class="row">
						<div class="col-12 ">
							<div class="template_preview_panel docpreview">
								<div class="preview_header">
									<h5> Preview Panel</h5>
								</div>
								<div class="preview_body h-100">
									<div id="content" #content>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center mt-3">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" id="pagetemplate1" [ngStyle]="{'display':displayhideAnnex1}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="model-action text-right">

				<a class="action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
						alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<h5 class="model_title">Preview Template</h5>
						</div>
					</div>
					<div class="row">

						<div class="col-12 ">
							<div class="template_preview_panel docpreview">
								<div class="preview_header">
									<h5> Preview Panel</h5>

								</div>
								<div class="preview_body h-100">

									<div id="content1" #content1>

									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center mt-3">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" id="pagetemplate2" [ngStyle]="{'display':displayhideAnnex2}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="model-action text-right">

				<a class="action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
						alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<h5 class="model_title">Preview Template</h5>
						</div>
					</div>
					<div class="row">

						<div class="col-12 ">
							<div class="template_preview_panel docpreview">
								<div class="preview_header">
									<h5> Preview Panel</h5>

								</div>
								<div class="preview_body h-100">

									<div id="content2" #content2>

									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center mt-3">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" id="pagetemplate3" [ngStyle]="{'display':displayhideAnnex3}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="model-action text-right">

				<a class="action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
						alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<h5 class="model_title">Preview Template</h5>
						</div>
					</div>
					<div class="row">

						<div class="col-12 ">
							<div class="template_preview_panel docpreview">
								<div class="preview_header">
									<h5> Preview Panel</h5>

								</div>
								<div class="preview_body h-100">

									<div id="content3" #content3>

									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center mt-3">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" id="pagetemplate4" [ngStyle]="{'display':displayhideAnnex4}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="model-action text-right">

				<a class="action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
						alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<h5 class="model_title">Preview Template</h5>
						</div>
					</div>
					<div class="row">

						<div class="col-12 ">
							<div class="template_preview_panel docpreview">
								<div class="preview_header">
									<h5> Preview Panel</h5>

								</div>
								<div class="preview_body h-100">

									<div id="content4" #content4>

									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center mt-3">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal" id="pagetemplate5" [ngStyle]="{'display':displayhideAnnex5}">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="model-action text-right">

				<a class="action_icon ml-3" data-dismiss="modal"><img src="assets/images/close.svg" class="img-fluid"
						alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<h5 class="model_title">Preview Template</h5>
						</div>
					</div>
					<div class="row">

						<div class="col-12 ">
							<div class="template_preview_panel docpreview">
								<div class="preview_header">
									<h5> Preview Panel</h5>

								</div>
								<div class="preview_body h-100">

									<div id="content5" #content5>

									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center mt-3">

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div [innerHTML]="trustedHtml"></div>
<!--Preview Letter Modal start here -->
<div class="modal" id="viewwizard" [ngStyle]="{'display':displayQushistory}">
	<div class="modal-dialog modal-lg modal-dialog-centered new_popup">
		<div class="modal-content">
			<div class="model-action text-right">
				<a class="action_icon mx-3 mt-2 closed" data-dismiss="modal" (click)="closePopup()">
					<img src="assets/images/close.svg" class="img-fluid" alt="close" /></a>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="preview_header d-flex align-items-center justify-content-between block_details">
						<div class="d-flex">
							<h5 class=" block_label"><span class="block_first_name">Template Name: </span> <span
									class="block_attribute">{{TemplateName}}</span></h5>
							<h5 class=" block_label"><span class="block_first_name">Engagement Letter Name: </span>
								<span class="block_attribute">{{EngageLetterName}}</span>
							</h5>
						</div>
					</div>

					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="preview_body">

								<div class="preview_inner h-100">
									<div *ngFor="let value of questionJSON; let i=index">
										<h5>{{i+1}}. {{value.QuestionName}}</h5>
										<div *ngFor="let qusChoices of value.QuestionChoices; let i=index">
											<input type="radio" style="pointer-events: none !important;"
												[checked]="value.QuestionResponse==qusChoices.value">
											<label>{{qusChoices.value}}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- Preview Letter Modal End here -->

<div class="modal" id="cancelfield" [ngStyle]="{'display':warning}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" class="close mx-3 mt-2" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img src="assets/images/Error.svg" class="img-fluid" alt="warning" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Please reset the browser zoom settings to default. </h5>
								</div>
								<div class="col-sm-12 text-center">
									<a class="btn btn-primary mx-2" data-dismiss="modal">Close</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- Decline for a Reason-->
<div class="modal" id="cancelfield" [ngStyle]="{'display':displayDeclineReason}">
	<div class="modal-dialog  modal-dialog-centered new_popup" style="
    width: 788px;">
		<div class="modal-content">

			<div class="modal-body">
				<div class="container-fluid">

					<div class="col-12 col-sm-12 col-sm-12">
						<h5 class="mb-3"><b style="margin-left:-16px">Reason for Declined</b></h5>
					</div>
					<div class="row">
						<div class="col-sm-4"><strong>Email Id</strong></div>
						<div class="col-sm-8 new_label_structure mb-2">
							<p><span>: {{ this.ClientEmailId }}</span></p>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-4"><strong>Timestamp</strong></div>
						<div class="col-sm-8 new_label_structure mb-2">
							<p><span>: {{ this.LastModified }}</span></p>
						</div>
					</div>


					<div class="form-outline">
						<textarea name="ans" [(ngModel)]="this.reasonforDecline" class="form-control"
							id="textAreaExample1" rows="4" style="
							border: 1px solid #ced4da;
						" readonly>

					</textarea>

						<div class="col-sm-12 text-right mt-2" style="margin-left: 25px;">
							<a (click)="closedeclinePopup()" class="btn btn-secondary mx-2" data-toggle="modal"
								data-target="#clearletter">Close</a>
						</div>
					</div>


				</div>
			</div>
		</div>
	</div>
</div>
<!-- Reassigned History Popup-->
<div class="modal" id="cancelfield" [ngStyle]="{'display':displayReassignedPopup}">
	<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		<div class="modal-content">

			<div class="modal-body">
				<div class="container-fluid">

					<div class="col-12 col-sm-12 col-sm-12">
						<h5 class="mb-3"><b style="margin-left:-16px">Reassigned Log</b></h5>
					</div>
					<div class="col-12 col-sm-12 col-md-12">
						<div class="table-responsive Engagementtable">

							<table class="table table-bordered ">
								<thead>
									<tr>
										<th scope="col" class="newclass">Action</th>
										<th scope="col" class="newclass">Reassigned From</th>
										<th scope="col" class="newclass">Reassigned To</th>
										<th scope="col" class="newclass">Reassigned By</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngIf='this.engsprole=="c6780d12-a182-460e-8876-0ebb82e280bd"'>
										<td>Reassigned SP</td>
										<td>{{ this.SigningPartnerOverideFromNamereassign}}</td>
										<td>{{ this.SigningPartnerOverideToNamereassign}}</td>
										<td>{{this.reassigneditedby}}</td>
									</tr>
									<tr *ngIf='this.engrprole=="1141b120-5e5f-4547-bf20-694343fe8adf"'>
										<td>Reassigned RP</td>
										<td>{{ this.ResponsiblePersonOverideFromNamereassign}}</td>
										<td>{{ this.ResponsiblePersonOverideToNamereassign}}</td>
										<td>{{this.reassigneditedby}}</td>
									</tr>
									<tr *ngIf='this.enginitiatorrole=="Initiator"'>
										<td>Reassigned Initiator</td>
										<td>{{this.InitiatorOverideFromNamereassign}}</td>
										<td>{{this.InitiatorOverideToNamereassign}}</td>
										<td>{{this.reassigneditedby}}</td>
									</tr>
									<tr *ngIf='this.engwprolerole=="Word Processing"'>
										<td>Reassigned WP</td>
										<td>{{this.DocumentCurrentAssignedForNamereassign}}</td>
										<td>{{this.DocumentOverrideToNamereassign}}</td>
										<td>{{this.reassigneditedby}}</td>
									</tr>
									<tr *ngIf='this.engwprolerole=="Quality Control"'>
										<td>Reassigned QC</td>
										<td>{{this.DocumentCurrentAssignedForNamereassign}}</td>
										<td>{{this.DocumentOverrideToNamereassign}}</td>
										<td>{{this.reassigneditedby}}</td>


									</tr>
									<tr
										*ngIf='this.engwprolerole!="Quality Control" && this.engwprolerole!="Word Processing" && this.enginitiatorrole!="Initiator" && this.engrprole=="1141b120-5e5f-4547-bf20-694343fe8adf" && this.engsprole=="c6780d12-a182-460e-8876-0ebb82e280bd"'>
										<td colspan="12" style="text-align: center;">No Record Found</td>



									</tr>
								</tbody>
							</table>
						</div>
					</div>



					<div class="col-sm-12 text-right mt-2" style="margin-left: 25px;">
						<a (click)="closedreassignedPopup()" class="btn btn-secondary mx-2" data-toggle="modal"
							data-target="#clearletter">Close</a>
					</div>



				</div>
			</div>
		</div>
	</div>
</div>
<!--Error popup Message -->
<div class="modal" id="cancelfield" [ngStyle]="{'display':displayeversignerror}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" (click)="ondefaultEversignError()" class="close mx-3 mt-2"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img style="width:60px;" src="assets/images/error_icon.svg" class="img-fluid"
										alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Sorry, something went wrong.</h5><br>
									<h5>To continue please logout, close the browser, login again and resume the work on
										this letter.</h5>
									<h5>If any problem occurs again in this letter, please take a full screen screenshot
										showing complete browser along with date time and share with IT Support.</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="ondefaultEversignError()" class="btn btn-secondary mx-2"
										data-dismiss="modal">Ok</a>
									<!-- <a (click)="onClosedraft()" class="btn btn-secondary mx-2" data-dismiss="modal"
                                        data-dismiss="modal">No</a> -->

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="cancelfield" [ngStyle]="{'display':displayLogerror}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" (click)="ondefaultLogError()" class="close mx-3 mt-2"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img style="width:60px;" src="assets/images/error_icon.svg" class="img-fluid"
										alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>Sorry, something went wrong.</h5><br>
									<h5>To continue please logout, close the browser, login again and resume the work on
										this letter.</h5>
									<h5>If any problem occurs again in this letter, please take a full screen screenshot
										showing complete browser along with date time and share with IT Support.</h5>
								</div>
								<div class="col-sm-12 text-center">
									<a (click)="ondefaultLogError()" class="btn btn-secondary mx-2"
										data-dismiss="modal">Ok</a>
									<!-- <a (click)="onClosedraft()" class="btn btn-secondary mx-2" data-dismiss="modal"
                                        data-dismiss="modal">No</a> -->

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal" id="cancelfield" [ngStyle]="{'display':displayadobeurlerrorpopup}">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="model-action">
				<button type="button" (click)="ondefaultadobeError()" class="close mx-3 mt-2"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12">
							<div class="row">
								<div class="col-sm-12 text-center mb-4">
									<img style="width:60px;" src="assets/images/error_icon.svg" class="img-fluid"
										alt="dp" />
								</div>
								<div class="col-sm-12 text-center mb-3 error_text">
									<h5>{{this.ErrorPopupMessageadobe}}</h5>

								</div>
								<div class="col-sm-12 text-center">
									<a (click)="ondefaultadobeError()" class="btn btn-secondary mx-2"
										data-dismiss="modal">Ok</a>
									<!-- <a (click)="onClosedraft()" class="btn btn-secondary mx-2" data-dismiss="modal"
                                        data-dismiss="modal">No</a> -->

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>