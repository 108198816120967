import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicelinksService } from '../shared/servicelinks.service';
import { environment } from '../../environments/environment';

let headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'responseType': 'json'
});
let options = {
  headers: headers
}

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'responseType': 'json'
    })
}

@Injectable({
  providedIn: 'root'
})
export class InitiatorService {
  public departmentVal: string = '';
  public groupVal: string = '';
  public typeVal: string = '';
  public basisVal: string = '';
  public stateVal: string = '';
  public addressVal: string = '';
  public entityVal: string = '';
  public cityVal: string = '';
  public zipVal: string = '';
  public yearVal: string = '';
  public contactVal: string = '';
  public dateVal: string = '';
  constructor(private servicelinks: ServicelinksService, private http: HttpClient) {
  }
  getAllInitiator(): Promise<any> {
    let url = this.servicelinks.initiatorlistUrl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  rolloverCall(data: any): Promise<any> {
    let url = this.servicelinks.rolloverurl;
    return new Promise((resolve, reject) => {
      this.http.post(url, data, options)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }


  findrollover(): Promise<any> {
    let url = this.servicelinks.rollovermethodurl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  rolloverMethod(data: any): Promise<any> {
    let url = this.servicelinks.findollovermethodurl;
    return new Promise((resolve, reject) => {
      this.http.post(url, data, options)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  getAllInitiatorDropDown(): Promise<any> {
    let url = this.servicelinks.initiatordropdown;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((data: any) => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }


  getAllSavedFilters(): Promise<any> {
    let url = this.servicelinks.initiatorlistUrl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  deletedata(data: any): Promise<any> {
    let url = this.servicelinks.initiatordeleteUrl;
    console.log(url, 'testurl');
    console.log(data, 'data');
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }


  //   getAllFilter(): Promise<any> {
  //     let url = this.servicelinks.GetEngagementLetterFilterFields;
  //     return new Promise((resolve, reject) => {
  //         this.http.get(url)
  //             .subscribe((response: any) => {
  //                 resolve(response);
  //             }, error => {
  //                 reject(error);
  //             });
  //     }
  //     );
  // }


  getAllFilter(UserId: number): Promise<any> {
    console.log(UserId);
    let url = this.servicelinks.GetEngagementLetterFilterFields;
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${UserId}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }

  saveInitiator(data: any): Promise<any> {
    let url = this.servicelinks.saveInitiatorUrl;
    //  let responseBody:{} = JSON.stringify(data);
    console.log(url);
    console.log(data);
    //console.log(responseBody);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => { 
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  searchInitiator(data: any): Promise<any> {
    let url = this.servicelinks.searchInitiatorUrl;
    //  let responseBody:{} = JSON.stringify(data);
    console.log(url);
    console.log(data);
    //console.log(responseBody);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => { 
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }


  getSavedFilterById(FilterId: number): Promise<any> {
    let url = this.servicelinks.getSavedFilterById;
    return new Promise((resolve, reject) => {
      this.http.get(`${url}/${FilterId}`)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  editlettername(data: any): Promise<any> {
    let url = this.servicelinks.editlettername;
    //  let responseBody:{} = JSON.stringify(data);
    console.log(url);
    console.log(data);
    //console.log(responseBody);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => { 
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  sendinfovaluejson(data: any): Promise<any> {
    let url = this.servicelinks.sendinfovaluejson;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getinfovaluejson(data: any): Promise<any> {
    let url = this.servicelinks.getinfovaluejson;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, data).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getblockjson(data: any): Promise<any> {
    let url = this.servicelinks.getcontentblock;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  updateblockcontent(data: any): Promise<any> {
    let url = this.servicelinks.updateblockcontent;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  submitcontentLogic(data: any): Promise<any> {
    // let url = this.servicelinks.submitcontent;
    let apiAddress: string = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationFirstStage";
    console.log(apiAddress);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(apiAddress, data, httpOptions).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  submitlogic(data: any): Promise<any> {
    // let url = this.servicelinks.submitcontent;
    let apiAddress: string = environment.baseApiUrl + "api/EngagementLetter/AddLetterGenerationThirdStage";
    console.log(apiAddress);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.put(apiAddress, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  saveDraft(data: any): Promise<any> {
    let url = this.servicelinks.saveDraftInitiatorUrl;
    //  let responseBody:{} = JSON.stringify(data);
    console.log(url);
    console.log(data);
    //console.log(responseBody);
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {
        // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => { 
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  detailsInitiatorDropdown(EngagementLetterId: any): Promise<any> {
    let url = this.servicelinks.detailsinitiatorDropdownUrl;
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${EngagementLetterId}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }
  saveDraftChoose(data: any): Promise<any> {
    let url = this.servicelinks.saveDraftchooseInitiatorUrl;
    // let responseBody:{} = JSON.stringify(data);
    console.log(url);
    console.log(data);
    //console.log(responseBody);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  addlettergenerationsecondstage(data: any): Promise<any> {
    let url = this.servicelinks.addLetterGenerationSecondStage;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  getAllClientNames(): Promise<any> {
    let url = this.servicelinks.getclientnames;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  GetADDInfoFields(data: any): Promise<any> {
    let url = this.servicelinks.getAddInfoFields;
    // let responseBody:{} = JSON.stringify(data);
    console.log(url);
    console.log(data);
    //console.log(responseBody);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        // this.http.post(url, responseBody,{headers: this.headers} ).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  ChooseActionReviewDropdown(data: any): Promise<any> {
    let url = this.servicelinks.chooseactionUrl;

    console.log(url);
    console.log(data);

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {

        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  AddInfoApplyChanges(data: any): Promise<any> {
    let url = this.servicelinks.applychanges;

    console.log(url);
    console.log(data);

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {

        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  SubmitReview(data: any): Promise<any> {
    let url = this.servicelinks.submitreview;

    console.log(url);
    console.log(data);

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {

        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  SubmitReviewfinal(data: any): Promise<any> {
    let url = this.servicelinks.applychanges;

    console.log(url);
    console.log(data);

    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {

        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }


  EditvaluesHistory(data: any): Promise<any> {
    let url = this.servicelinks.EditvalHistory;

    console.log(url);
    console.log(data);

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {

        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  getdefaultsetting(): Promise<any> {
    let url = this.servicelinks.getLetterwatermarksettings;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  getdefaultsettingletter(): Promise<any> {
    let url = this.servicelinks.getLetterlettersettings;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  saveOverRide(data: any): Promise<any> {
    let url = this.servicelinks.saveOverRide;
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });

  }

  submitpdf(data: any): Promise<any> {
    let url = this.servicelinks.pdfdownload;
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, options).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });

  }

  getEversignToken(): Promise<any> {
    let url = this.servicelinks.eversigntokenurl;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  saveProceedSigningData(data: any): Promise<any> {

    let saveUrl = this.servicelinks.proceedforsigningurl;
    console.log(saveUrl)

    return new Promise<any>((resolve, reject) => {

      this.http.post(saveUrl, data, options).subscribe((res) => {

        resolve(res);

      }, error => {
        reject(error)
      })
    })
  }
  attachementFile1(data: any): Promise<any> {
    let fileUrl = this.servicelinks.attachement1Api;

    return new Promise<any>((resolve, reject) => {

      this.http.post(fileUrl, data, options).subscribe((res) => {
        resolve(res);
      }, error => {
        reject(error)
      })

    })
  }

  attachementFile2(data: any): Promise<any> {
    let fileUrl = this.servicelinks.attachement2Api;

    return new Promise<any>((resolve, reject) => {

      this.http.post(fileUrl, data, options).subscribe((res) => {
        resolve(res);
      }, error => {
        reject(error)
      })

    })
  }

  getContactName1(EngagementLetterId: any): Promise<any> {


    let nameUrl = this.servicelinks.addPopupContactPersonName1;
    return new Promise((resolve, reject) => {

      this.http.get(`${nameUrl}/${EngagementLetterId}`).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      }
      );
    })
  }

  getSigningStatus(data: any): Promise<any> {
    let url = this.servicelinks.getStatussigning;
    return new Promise((resolve, reject) => {
      this.http.post(url, data, options)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  SigningStatusChange(data: any): Promise<any> {
    let url = this.servicelinks.signingstatuschange;
    return new Promise((resolve, reject) => {
      this.http.post(url, data, options)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  RefreshStatus(data: any): Promise<any> {
    let url = this.servicelinks.refreshStatus;
    return new Promise((resolve, reject) => {
      this.http.post(url, data, options)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  UploadPdf(data: any): Promise<any> {
    let url = this.servicelinks.uploadpdf;
    return new Promise((resolve, reject) => {
      this.http.post(url, data, options)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }
  getclientdetailsById(Id: number): Promise<any> {
    console.log(Id);
    let url = this.servicelinks.getclientdetailsbyid;
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${Id}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }
  getHistoryLog(EngagementLetterId: any): Promise<any> {
    let url = this.servicelinks.historyLog;
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${EngagementLetterId}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }
  engagementPdfDownload(EngagementLetterId: any): Promise<any> {
    let url = this.servicelinks.engagementPdfDownload;
    console.log(EngagementLetterId);
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${EngagementLetterId}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }

  gettypes(TypeId: any): Promise<any> {


    let nameUrl = this.servicelinks.gettypesbyid;
    return new Promise((resolve, reject) => {

      this.http.get(`${nameUrl}/${TypeId}`).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      }
      );
    })
  }

  getbasis(BasisId: any): Promise<any> {


    let nameUrl = this.servicelinks.getbasisbyid;
    return new Promise((resolve, reject) => {

      this.http.get(`${nameUrl}/${BasisId}`).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      }
      );
    })
  }

  getAllSignedData(): Promise<any> {
    let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get(url)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    }
    );
  }

  getformattedhtmlcontent(data: any): Promise<any> {
    // let url = this.servicelinks.submitcontent;
    let apiAddress: string = environment.baseApiUrl + "api/EngagementLetter/GetFormattedHtmlContent";
    console.log(apiAddress);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(apiAddress, data, httpOptions).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  cancelsigning(data: any): Promise<any> {
    let url = this.servicelinks.cancelsigning;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, httpOptions)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }

  saveProceedSigningDataHiddenTags(data: any): Promise<any> {

    let saveUrl = this.servicelinks.proceedforsigninghiddentagsurl;
    console.log(saveUrl)

    return new Promise<any>((resolve, reject) => {

      this.http.post(saveUrl, data, options).subscribe((res) => {

        resolve(res);

      }, error => {
        reject(error)
      })
    })
  }


  saveProceedManualSigningDataHiddenTags(data: any): Promise<any> {

    let saveManualUrl = this.servicelinks.proceedforManualsigninghiddentags;
    console.log(saveManualUrl)

    return new Promise<any>((resolve, reject) => {

      this.http.post(saveManualUrl, data, options).subscribe((res) => {

        resolve(res);

      }, error => {
        reject(error)
      })
    })
  }

  getreassignedhistorylog(data: any): Promise<any> {
    let url = this.servicelinks.reassignedhistorylog;
    console.log(url, 'testurl');
    console.log(data, 'data');
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  ManualSigningPdfDownload(EngagementLetterId: any): Promise<any> {
    let url = this.servicelinks.ManualSigningPdfDownload;
    console.log(EngagementLetterId);
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${url}/${EngagementLetterId}`)
        .subscribe(res => {
          console.log(res);
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        });
    });

  }

  getconsolelogcapture(data: any): Promise<any> {
    let url = this.servicelinks.consolelogcapture;

    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {


        resolve(res);
      }, err => {

        reject(err);
      });
    });
  }
  getletterheadcontent(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/letterheadcontent.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }
  getletterwatermarkcontent(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/letterwatermarkcontent.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }

  getlettercontent(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/lettercontent.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }
  getlettercontentPage(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/lettercontentpage.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }
  getletterheadcontentPage(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/letterheadcontentpage.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }
  getletterwatermarkcontentPage(): Promise<any> {
    //let url = this.servicelinks.getsignedname;
    return new Promise((resolve, reject) => {
      this.http.get('assets/previewtemplates/letterwatermarkcontentpage.html', { responseType: 'text' })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    }
    );
  }

  SubmitAdobeSigningData(data: any): Promise<any> {
    let url = this.servicelinks.SubmitAdobeSigningData;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  HtmltoURLConvertData(data: any): Promise<any> {
    let url = this.servicelinks.HtmltoURLConvertData;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  AdobePathURLData(data: any): Promise<any> {
    let url = this.servicelinks.AdobePathURLData;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }

  PreviewPathURLData(data: any): Promise<any> {
    let url = this.servicelinks.PreviewPathURLData;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getmultipleblockjson(data: any): Promise<any> {
    let url = this.servicelinks.getmulticontentblock;
    console.log(url);
    console.log(data);
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getclientdetails() {
    let url = this.servicelinks.getclientdetails;
    //let url = 'http://localhost:5189/api/EngagementLetter/GetAllClientAsync';
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  getbulklbatchlist() {
    let url = this.servicelinks.getbulkletterslist;
    //let url = 'http://localhost:5189/api/EngagementLetter/GetAllEngagementLettersAsync';
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }
  submitbulkletterlist(data: any): Promise<any> {
    //let url = 'http://localhost:5189/api/EngagementLetter/InsertBulkRequestAsync';
    let url = this.servicelinks.insertbulkgen;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, options).subscribe(res => {
        console.log(res);

        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      });
    });
  }


}

