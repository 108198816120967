<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
<section class="Login_wrapper w-100  new_login_section">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please Wait. </p>
    </ngx-spinner>
    <div class="container-fluid h-100">
        <div class="row  p-0 h-100">
            <div class="col-md-7 left-image p-0">
                <!-- <img class="img-fluid" src="./images/Mask group.jpg" alt="No-Image"> -->
            </div>
            <div class="col-md-5 login p-0 ">
                <a class="mt-5" href="#"><img src="assets/images/cp_logo.png" alt="No-image"></a>
                <div class="handshake-logo">
                    <img src="assets/images/handshake_logo.png" alt="No-Image">
                    <h2>HANDSHAKE</h2>
                    <ng-container *ngIf="this.formBasedAuthLogin">
                        <form class="form-horizontal customLogin" [formGroup]="loginForm" (ngSubmit)="onloginForm()">
                            <div class="form-group col-md-9" style="margin: auto">
                                <label class="control-label title" for="displayname">Email<span
                                        class='red-asterisk'>*</span></label>
                                <input type="text" class="form-control input-md"
                                    [ngClass]="{'invalid-input': submitted && loginForm.controls['email'].invalid}"
                                    formControlName="email" placeholder="Enter Email ID">
                            </div>

                            <div class="form-group col-md-9" style="margin: auto">
                                <label class="control-label title mt-1" for="displayname">Password<span
                                        class='red-asterisk'>*</span></label>
                                <input type="password" class="form-control input-md"
                                    [ngClass]="{'invalid-input': submitted && loginForm.controls['password'].invalid}"
                                    formControlName="password" placeholder="Enter Password">
                            </div>

                            <div class="demo-btn" style="    padding: 20px;padding-bottom: 0px;">
                                <button type="submit" style="padding: 10px 20px;font-size: 13px">LOGIN<span><i
                                            class="fa-solid fa-chevron-right ms-1"></i></span></button>
                            </div>
                        </form>
                    </ng-container>

                    <div class="modal" tabindex="-1" id="success" *ngIf="this.isError"
                        [ngStyle]="{'display':displayErrorModal}">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content" id="success">
                                <div class="modal-header error-modal">
                                    <h5 class="modal-title fw-semibold highlight fs-18 text-white">Error</h5>
                                    <button type="button" (click)="closeSuccessModal()" class="btn-close shadow-none"
                                        data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body text-center">
                                    <img src="./assets/images/error-icon.png" class="mb-3" alt="warning">
                                    <p class="fs-16">Email address or Password is incorrect</p>
                                    <button type="button" (click)="ondefaultLogError()" class="error-btn"
                                        data-bs-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="this.formBasedAuthLogin == false">
                        <div class="demo-btn mt-5">
                            <button href="#" (click)="onLoginNew()">LOGIN<span><i
                                        class="fa-solid fa-chevron-right ms-1"></i></span></button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <footer>
            <p class="text-end me-3"><a href="#" style="font-size: 12px">©2024 Changepond. All Rights Reserved</a></p>
        </footer>
    </div>
</section>